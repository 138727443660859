.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.mobilemenu {
  position: fixed;
  z-index: 30000;
  right: 0;
  top: var(--topbar-height)-1px;
  height: calc(100vh - var(--topbar-height) + 1px);
  background: #fff;
  transition: transform 0.3s;
  left: 500vh;
  transform: translateX(-120vw);
  opacity: 0;
  pointer-events: none;
  overflow-y: scroll;
}
html.ios .mobilemenu {
  padding-bottom: 114px;
}
.mobilemenu.initialized {
  display: block !important;
  left: 0;
  opacity: 1;
  pointer-events: auto;
}
html.mobile-menu-open .mobilemenu {
  transform: translateX(0);
}
.mobilemenu__nav {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}
.mobilemenu__sites {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
}
.mobilemenu .accordion__toggle {
  top: -4px;
}
.mobilemenu .accordion .card {
  margin-bottom: 10px;
}
.mobilemenu .accordion .card-header {
  padding-bottom: 6px;
  margin-bottom: 6px;
}
.mobilemenu__navlistitems {
  padding: 1rem 1rem 0;
}
html .mobilemenu .accordion__cardheaderlink:not(.selected) {
  color: #606060;
}
html.site-general .mobilemenu .ctatextlinks.selected .ctatextlinks__link {
  color: #d40f4d;
}
html.site-advice .mobilemenu .ctatextlinks.selected .ctatextlinks__link {
  color: #f17156;
}
html.site-work .mobilemenu .ctatextlinks.selected .ctatextlinks__link {
  color: #8fb8ec;
}
html.site-care .mobilemenu .ctatextlinks.selected .ctatextlinks__link {
  color: #4969f8;
}
.mobilemenu__sites a {
  padding: 2rem 1rem;
}