/* This file is also loaded by the site */

@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

/* FIXME: move to rtd-editor.css, but the RTD doesn't seem to be able to load 2 CSS files */
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css');

body,
h1,
h2,
h4 {
  font-family: 'Quattrocento Sans', sans-serif;
  line-height: 100%;
}

body,
h1,
h3,
p {
  color: #606060;
}

h2,
h4,
a {
  color: #D40F4D;
}

body,
p,
.wh-rtd-embeddedobject {
  font-size: 17px;
  line-height: 23px;
}

h3,
p.intro {
  font-family: 'Libre Baskerville', sans-serif
}

h1, .h1 {
  font-size: 77px;
  font-weight: 700;
  margin: 0 0 20px;
}

h2, .h2 {
  font-size: 67px;
  font-weight: 700;
  margin: 0 0 20px;
}

h3, .h3 {
  font-size: 24px;
  line-height: 33px;
  margin: 0 0 20px;
}

h4, .h4 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  margin: 0 0 20px;
}

p,
.p,
.wh-rtd-embeddedobject,
.widget {
  margin: 0 0 10px;
}

.intro {
  font-size: 20px;
  line-height: 33px;
}

a {
  text-decoration: underline;
  transition: color .3s;
}

a:hover {
  text-decoration: none;
}

hr {
  margin: 15px 0;
  border: 1px solid #999;
}

.wh-rtd__tablewrap.table {
  overflow-x: auto;
}

.wh-rtd-editor {
  padding: 3px;
}

.wh-rtd-editor .widget {
  padding: 15px 15px 30px;
}

.wh-rtd-editor .widget__type {
  position: absolute;
  bottom: 10px;
  right: 12px;
  font-size: 16px;
  font-style: italic;
}

.wh-rtd-embeddedobject .wh-rtd-embeddedobject {
  display: none;
}
