.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.widget--quotes.widget--site {
  position: relative;
}
.widget--quotes.widget--site .widget--quotes__background {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 13vw), 100% 0, 100% calc(100% - 13vw), 0 100%);
  position: absolute;
  bottom: -70px;
  top: -840px;
  left: 0;
  right: 0;
  background: #ecf3fc;
  z-index: -100;
}

.w-quotes {
  margin: 0 auto;
  width: 812px;
  max-width: 100%;
  position: relative;
}
@media screen and (max-width: 812px) {
  .w-quotes {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.w-quotes .c-quote {
  width: 100%;
}
.w-quotes .c-quote__image {
  margin-bottom: 40px;
}
.w-quotes .c-quote__cols {
  display: flex;
  flex-direction: column;
}
.w-quotes .c-quote__col {
  width: 100%;
}
.w-quotes .c-quote__col--image {
  max-height: 520px;
  position: relative;
}
.w-quotes .c-quote__col--image::before, .w-quotes .c-quote__col--image::after {
  content: "";
  display: table;
  clear: both;
  width: 1px;
  margin-left: -1px;
}
.w-quotes .c-quote__col--image::before {
  float: left;
  padding-bottom: 148.5714285714%;
}
.w-quotes .c-quote__col--quote {
  margin: 3rem 0 0;
}
.w-quotes .c-quote__image {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.w-quotes .c-quote__personinfo {
  font-size: 15px;
  line-height: 20px;
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
  position: absolute !important;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  padding-bottom: 50px;
  bottom: -64px;
}
.w-quotes .c-quote__name {
  font-weight: bold;
}
@media (min-width: 768px) {
  .w-quotes .c-quote__cols {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 85px;
    padding-right: 85px;
  }
  .w-quotes .c-quote__col {
    width: calc(50% - 20px);
  }
  .w-quotes .c-quote__col--image {
    order: 2;
  }
  .w-quotes .c-quote__col--quote {
    margin-top: 0;
  }
  .w-quotes .c-quote__image {
    bottom: 33px;
  }
  .w-quotes .c-quote__personinfo {
    position: relative;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 4vw), 0 100%);
  }
}
@media (min-width: 992px) {
  .w-quotes .c-quote__cols {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .w-quotes .c-quote__personinfo {
    position: relative;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2vw), 0 100%);
  }
}
.w-quotes .roundnavbutton {
  position: absolute;
  top: 15px;
}
.w-quotes .roundnavbutton--next {
  right: 30px;
}
.w-quotes .roundnavbutton--prev {
  left: 30px;
}
@media (min-width: 768px) {
  .w-quotes .roundnavbutton {
    top: 50%;
    transform: translateY(-50%);
  }
  .w-quotes .roundnavbutton--next {
    right: 0;
  }
  .w-quotes .roundnavbutton--prev {
    left: 0;
    transform: scaleX(-1) translateY(-50%);
  }
}
@media (min-width: 992px) {
  .w-quotes .roundnavbutton--next {
    right: -76px;
  }
  .w-quotes .roundnavbutton--prev {
    left: -76px;
  }
}
@media (min-width: 1200px) {
  .w-quotes .roundnavbutton--next {
    right: -146px;
  }
  .w-quotes .roundnavbutton--prev {
    left: -146px;
  }
}
@media (min-width: 1400px) {
  .w-quotes .roundnavbutton--next {
    right: -206px;
  }
  .w-quotes .roundnavbutton--prev {
    left: -206px;
  }
}