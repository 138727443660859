@charset "UTF-8";
.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.p-home__quote {
  display: none;
  position: absolute;
  bottom: calc(100% + 60px);
  left: 0;
  background: #fff;
  color: #000;
  border-radius: 3em;
  padding: 2.7rem 0.5rem 2.7rem 3.5rem;
  width: 330px;
  line-height: 140%;
  pointer-events: auto;
  font-weight: 700;
}
@media screen and (min-width: 900px) {
  .p-home__quote {
    display: block;
  }
}
@media screen and (min-width: 1050px) {
  .p-home__quote {
    left: 2rem;
  }
}
.p-home__quote:before {
  content: "“";
  top: 1rem;
  left: 1rem;
}

.p-home__quote:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 35px solid transparent;
  border-top-color: #fff;
  border-bottom: 0;
  border-left: 0;
  margin-left: -17.5px;
  margin-bottom: -35px;
}

.p-home__quote:before,
.p-home__quote-quote-after {
  font: 700 90px/100% "Libre Baskerville", sans-serif;
  position: absolute;
  height: 20px;
}

.p-home__quote-quote-after {
  bottom: 0.75rem;
  right: 1rem;
  transform: scale(-1);
}

.p-home {
  position: relative;
}
.p-home__headercontainer {
  padding-left: 1rem;
  padding-right: 1rem;
}
.p-home .p-home__buttons {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.2rem 1rem;
}
@media (min-width: 768px) {
  .p-home .p-home__buttons {
    grid-template-columns: repeat(2, 1fr);
  }
}
.p-home__header {
  position: relative;
  padding-top: 48px;
}
.p-home__header h1,
.p-home__header p,
.p-home__header .p-home__ctas a {
  color: #fff;
}
.p-home__ctas .ctatextlinks .icon-arrow-right .stroke {
  stroke: #fff;
}
.p-home__ctas .ctatextlinks__link {
  border-color: #F7F7F7;
}
.p-home__ctas .ctatextlinks__title {
  display: block;
}
.p-home__headercontainer {
  position: relative;
  z-index: 10;
}
.p-home__headerimagecontainer {
  display: none;
}
.p-home__headerimage {
  position: absolute;
  z-index: 99999;
  bottom: 110px;
  left: 70px;
}
.p-home__headerimage > img {
  height: 557px;
}
.site-care .p-home__headerimage {
  bottom: 21px;
  left: 49px;
}
.p-home__headercol {
  max-width: 690px;
  position: relative;
}
@media (min-width: 992px) {
  .p-home__headercol {
    max-width: 586px;
  }
  .p-home__headerimagecontainer {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    pointer-events: none;
    left: calc(586px + 1rem);
    overflow: hidden;
  }
  .p-home__scrolldown {
    transform: translateY(-40px);
  }
  @supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)) {
    .p-home__header {
      padding-bottom: 3rem;
    }
    .p-home__scrolldown {
      margin: 3rem 0 0 !important;
      transform: none;
    }
  }
}
@media (min-width: 1200px) {
  .p-home__headercontainer {
    padding-left: 0;
    padding-right: 0;
  }
  .p-home__headercol {
    margin-left: 260px;
  }
  .p-home__headerimagecontainer {
    left: 846px;
  }
  .p-home__headerimage {
    left: 110px;
    bottom: 120px;
  }
  .site-care .p-home__headerimage {
    bottom: 41px;
    left: 49px;
  }
}
@media screen and (min-width: 1640px) {
  .p-home__headercol {
    margin: 0;
    max-width: 674px;
  }
  .p-home__headercontainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 1226px;
  }
  .p-home__headerimagecontainer {
    left: calc((100vw - 1226px) / 2 + 674px);
  }
}