@import "shared/rtd/rtd.css";
.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

:root {
  --djopzz-main-color: #117db4;
  --original-topbar-height: 60px;
  --body-padding-top: 60px;
  --topbar-height: 60px;
  --djopzz-header-height: 60px;
}

html.site-general .theme-color {
  color: #d40f4d;
}
html.site-advice .theme-color {
  color: #f17156;
}
html.site-work .theme-color {
  color: #8fb8ec;
}
html.site-care .theme-color {
  color: #4969f8;
}

html.site-general .theme-color.lighten {
  color: #e71054;
}
html.site-advice .theme-color.lighten {
  color: #f38169;
}
html.site-work .theme-color.lighten {
  color: #a0c3ef;
}
html.site-care .theme-color.lighten {
  color: #5d79f9;
}

html.site-general .theme-color.darken {
  color: #c60e48;
}
html.site-advice .theme-color.darken {
  color: #f06548;
}
html.site-work .theme-color.darken {
  color: #82b0ea;
}
html.site-care .theme-color.darken {
  color: #3a5df7;
}

.theme-background-color {
  color: #fff;
}
html.site-general .theme-background-color {
  background-color: #d40f4d;
}
html.site-advice .theme-background-color {
  background-color: #f17156;
}
html.site-work .theme-background-color {
  background-color: #8fb8ec;
}
html.site-care .theme-background-color {
  background-color: #4969f8;
}

html.site-general .theme-background-color.lighten {
  background-color: #e71054;
}
html.site-advice .theme-background-color.lighten {
  background-color: #f38169;
}
html.site-work .theme-background-color.lighten {
  background-color: #a0c3ef;
}
html.site-care .theme-background-color.lighten {
  background-color: #5d79f9;
}

html.site-general .theme-background-color.darken {
  background-color: #c60e48;
}
html.site-advice .theme-background-color.darken {
  background-color: #f06548;
}
html.site-work .theme-background-color.darken {
  background-color: #82b0ea;
}
html.site-care .theme-background-color.darken {
  background-color: #3a5df7;
}

.theme-background {
  color: #fff;
}
html.site-general .theme-background {
  background: #d40f4d;
}
html.site-advice .theme-background {
  background: #f17156;
}
html.site-work .theme-background {
  background: #8fb8ec;
}
html.site-care .theme-background {
  background: #4969f8;
}

html.site-general .theme-background.lighten {
  background: #e71054;
}
html.site-advice .theme-background.lighten {
  background: #f38169;
}
html.site-work .theme-background.lighten {
  background: #a0c3ef;
}
html.site-care .theme-background.lighten {
  background: #5d79f9;
}

html.site-general .theme-background.darken {
  background: #c60e48;
}
html.site-advice .theme-background.darken {
  background: #f06548;
}
html.site-work .theme-background.darken {
  background: #82b0ea;
}
html.site-care .theme-background.darken {
  background: #3a5df7;
}

html.site-general .theme-fill .fill {
  fill: #d40f4d;
}
html.site-advice .theme-fill .fill {
  fill: #f17156;
}
html.site-work .theme-fill .fill {
  fill: #8fb8ec;
}
html.site-care .theme-fill .fill {
  fill: #4969f8;
}

html.site-general .theme-fill.lighten .fill {
  fill: #e71054;
}
html.site-advice .theme-fill.lighten .fill {
  fill: #f38169;
}
html.site-work .theme-fill.lighten .fill {
  fill: #a0c3ef;
}
html.site-care .theme-fill.lighten .fill {
  fill: #5d79f9;
}

html.site-general .theme-fill.darken .fill {
  fill: #c60e48;
}
html.site-advice .theme-fill.darken .fill {
  fill: #f06548;
}
html.site-work .theme-fill.darken .fill {
  fill: #82b0ea;
}
html.site-care .theme-fill.darken .fill {
  fill: #3a5df7;
}

html.site-general .theme-stroke .stroke {
  stroke: #d40f4d;
}
html.site-advice .theme-stroke .stroke {
  stroke: #f17156;
}
html.site-work .theme-stroke .stroke {
  stroke: #8fb8ec;
}
html.site-care .theme-stroke .stroke {
  stroke: #4969f8;
}

html.site-general .theme-stroke.lighten .stroke {
  stroke: #e71054;
}
html.site-advice .theme-stroke.lighten .stroke {
  stroke: #f38169;
}
html.site-work .theme-stroke.lighten .stroke {
  stroke: #a0c3ef;
}
html.site-care .theme-stroke.lighten .stroke {
  stroke: #5d79f9;
}

html.site-general .theme-stroke.darken .stroke {
  stroke: #c60e48;
}
html.site-advice .theme-stroke.darken .stroke {
  stroke: #f06548;
}
html.site-work .theme-stroke.darken .stroke {
  stroke: #82b0ea;
}
html.site-care .theme-stroke.darken .stroke {
  stroke: #3a5df7;
}

html.site-general .theme-border-color {
  border-color: #d40f4d;
}
html.site-advice .theme-border-color {
  border-color: #f17156;
}
html.site-work .theme-border-color {
  border-color: #8fb8ec;
}
html.site-care .theme-border-color {
  border-color: #4969f8;
}

html.site-general .theme-border-color.lighten {
  border-color: #e71054;
}
html.site-advice .theme-border-color.lighten {
  border-color: #f38169;
}
html.site-work .theme-border-color.lighten {
  border-color: #a0c3ef;
}
html.site-care .theme-border-color.lighten {
  border-color: #5d79f9;
}

html.site-general .theme-border-color.darken {
  border-color: #c60e48;
}
html.site-advice .theme-border-color.darken {
  border-color: #f06548;
}
html.site-work .theme-border-color.darken {
  border-color: #82b0ea;
}
html.site-care .theme-border-color.darken {
  border-color: #3a5df7;
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

body {
  -webkit-font-smoothing: antialiased;
}

html.site-general h2,
html.site-general h4 {
  color: #d40f4d;
}
html.site-advice h2,
html.site-advice h4 {
  color: #f17156;
}
html.site-work h2,
html.site-work h4 {
  color: #8fb8ec;
}
html.site-care h2,
html.site-care h4 {
  color: #4969f8;
}

body,
p {
  line-height: 30px;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 2rem;
  }
}
html.site-general a {
  color: #d40f4d;
}
html.site-advice a {
  color: #f17156;
}
html.site-work a {
  color: #8fb8ec;
}
html.site-care a {
  color: #4969f8;
}
p.normal a {
  text-decoration: none;
  display: inline-flex;
  position: relative;
  z-index: 0;
  transition: height 0.3s, background 0.3s, color 0.3s;
  font-weight: 700;
}
@media (hover: hover) {
  p.normal a:hover {
    color: #fff;
  }
}
p.normal a::after {
  content: "";
  position: absolute;
  height: 1px;
  transition: height 0.2s, background 0.3s, color 0.3s, opacity 0.3s;
  z-index: -1;
  border-radius: 5px;
  bottom: 1px;
  left: -4px;
  right: -4px;
}
@media (hover: hover) {
  p.normal a:hover:after {
    height: calc(100% + 2px);
    color: #fff;
  }
}
@media (hover: hover) {
  a:hover {
    text-decoration: none;
  }
}

html.page-news .c-header__headingtitle,
html.page-news .c-header__headingsubtitle {
  display: none;
}
@media (hover: hover) {
  html.page-news .header .slideshow__button:hover {
    border: 1px solid #fff;
  }
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.footer {
  position: relative;
  margin: 16rem 0 0;
  z-index: 1;
}
.footer .contact__locationsbutton {
  min-width: 267px;
}
.footer__container {
  padding-bottom: 3rem;
  max-width: 1400px;
}
.footer__background {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 13vw), 100% 0, 100% 100%, 0 100%);
  position: absolute;
  bottom: 0;
  top: -210px;
  left: 0;
  right: 0;
  background: #ecf3fc;
  z-index: -1;
}
.footer .c-footer {
  margin: 10rem 0 0;
  text-align: center;
  position: relative;
}
.footer .c-footer__scrolltotopcontainer {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  z-index: 10;
  right: 1rem;
}
.footer .c-footer__container {
  max-width: 1388px;
  margin: 0 auto;
}
.footer .c-footer,
.footer .c-footer a {
  color: #fff;
  font-weight: bold;
}
.footer .c-footer__row > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.footer .c-footer__sites, .footer .c-footer__footerlinks, .footer .c-footer__termslinks, .footer .c-footer__copyrightlinks {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .c-footer__sites a, .footer .c-footer__footerlink, .footer .c-footer__termslinks a, .footer .c-footer__copyrightlinks a {
  text-decoration: none;
  display: inline-flex;
  position: relative;
  z-index: 0;
  transition: height 0.3s, background 0.3s, color 0.3s;
  font-weight: bold;
  margin: 0 1rem;
}
.footer .c-footer__sites a::after, .footer .c-footer__footerlink::after, .footer .c-footer__termslinks a::after, .footer .c-footer__copyrightlinks a::after {
  content: "";
  position: absolute;
  height: 1px;
  transition: height 0.2s, background 0.3s, color 0.3s, opacity 0.3s;
  bottom: -6px;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0;
}
@media (hover: hover) {
  .footer .c-footer__sites a:hover:after, .footer .c-footer__footerlink:hover:after, .footer .c-footer__termslinks a:hover:after, .footer .c-footer__copyrightlinks a:hover:after {
    opacity: 1;
  }
}
.footer .c-footer__sites a.selected, .footer .c-footer__footerlink.selected, .footer .c-footer__termslinks a.selected, .footer .c-footer__copyrightlinks a.selected {
  text-decoration: none;
  display: inline-flex;
  position: relative;
  z-index: 0;
  transition: height 0.3s, background 0.3s, color 0.3s;
}
.footer .c-footer__sites a.selected::after, .footer .c-footer__footerlink.selected::after, .footer .c-footer__termslinks a.selected::after, .footer .c-footer__copyrightlinks a.selected::after {
  content: "";
  position: absolute;
  height: 1px;
  transition: height 0.2s, background 0.3s, color 0.3s, opacity 0.3s;
  bottom: -6px;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 1;
}
@media (hover: hover) {
  .footer .c-footer__sites a.selected:hover:after, .footer .c-footer__footerlink.selected:hover:after, .footer .c-footer__termslinks a.selected:hover:after, .footer .c-footer__copyrightlinks a.selected:hover:after {
    opacity: 0;
  }
}
.footer .c-footer__sites a:after, .footer .c-footer__sites a.selected:after, .footer .c-footer__footerlink:after, .footer .c-footer__footerlink.selected:after, .footer .c-footer__termslinks a:after, .footer .c-footer__termslinks a.selected:after, .footer .c-footer__copyrightlinks a:after, .footer .c-footer__copyrightlinks a.selected:after {
  height: 2px;
}
.footer .c-footer__sites a:last-child, .footer .c-footer__footerlink--last, .footer .c-footer__termslinks a:last-child {
  margin-bottom: 0;
}
.footer .c-footer__footerlinks, .footer .c-footer__termslinks, .footer .c-footer__copyrightlinks {
  flex-direction: column;
}
.footer .c-footer__termslinks a,
.footer .c-footer__termslinks .c-footer__copyrighttext, .footer .c-footer__copyrightlinks a,
.footer .c-footer__copyrightlinks .c-footer__copyrighttext {
  font-size: 17px;
  line-height: 100%;
  margin-bottom: 1rem;
}
.footer .c-footer__termslinks .divider, .footer .c-footer__copyrightlinks .divider {
  display: none;
}
.footer .c-footer__sites a {
  font-size: 17px;
  line-height: 100%;
}
.footer .c-footer__footerlink {
  font-size: 20px;
  line-height: 100%;
  white-space: nowrap;
  margin-bottom: 2rem;
}
html.site-work .footer .c-footer__footerlink .badge {
  background-color: #f17156;
  color: #fff;
}
.footer .c-footer__copyrightlinks {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin-bottom: 0;
}
.footer .c-footer__copyrightlinks a {
  white-space: nowrap;
}
.footer .c-footer__termslinks a {
  text-decoration: none;
  display: inline-flex;
  position: relative;
  z-index: 0;
  transition: height 0.3s, background 0.3s, color 0.3s;
  margin-bottom: 2rem;
  white-space: nowrap;
}
.footer .c-footer__termslinks a::after {
  content: "";
  position: absolute;
  height: 1px;
  transition: height 0.2s, background 0.3s, color 0.3s, opacity 0.3s;
  bottom: -6px;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 1;
}
@media (hover: hover) {
  .footer .c-footer__termslinks a:hover:after {
    opacity: 0;
  }
}
@media (min-width: 768px) {
  .footer .c-footer__footerlinks, .footer .c-footer__termslinks {
    flex-direction: row;
  }
  .footer .c-footer__footerlink, .footer .c-footer__termslinks a {
    margin-bottom: 0;
  }
  .footer .c-footer__scrolltotopcontainer {
    right: 34px;
  }
}
@media (min-width: 992px) {
  .footer .c-footer__copyrightlinks a,
  .footer .c-footer__copyrightlinks .c-footer__copyrighttext {
    font-size: 15px;
    line-height: 100%;
  }
  .footer .c-footer__copyrightlinks {
    flex-direction: row;
  }
  .footer .c-footer__copyrightlinks a,
  .footer .c-footer__copyrightlinks .c-footer__copyrighttext {
    margin: 0 0 0.5rem;
  }
  .footer .c-footer__copyrightlinks .divider {
    display: inline;
    margin: 0 0.5rem;
  }
}
@media (min-width: 1200px) {
  .footer .c-footer__row--first {
    display: flex;
  }
  .footer .c-footer__row--second {
    display: flex;
    justify-content: space-between;
  }
  .footer .c-footer__row > div {
    border: 0;
  }
  .footer .c-footer__sites a:first-child {
    margin-left: 4rem;
  }
  .footer .c-footer__footerlinks {
    margin-left: auto;
    order: 10;
  }
  .footer .c-footer__footerlinks .button {
    display: none;
  }
  .footer .c-footer__logo {
    margin-left: 1rem;
  }
}
@media (min-width: 1400px) {
  .footer .c-footer__footerlinks .button {
    display: inline-flex;
  }
}
.footer .c-newsletter {
  max-width: 1450px;
  margin: 4rem auto;
  text-align: center;
}
.footer .c-newsletter__form {
  display: flex;
}
@media (min-width: 768px) {
  .footer .c-newsletter {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
}

html[data-sitetype=care] .c-footer__copyrightlinks-iso-logos {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
html[data-sitetype=care] .c-footer__copyrightlinks-iso-logos a {
  display: inline-flex !important;
  margin: 0 !important;
}
html[data-sitetype=care] .c-footer__copyrightlinks-iso-logos a::after {
  display: none !important;
}
html[data-sitetype=care] .c-footer__copyrightlinks-iso-logos img {
  height: 40px;
}
html[data-sitetype=care] .c-footer__copyrightlinks {
  flex-direction: column !important;
}
html[data-sitetype=care] .footer .c-footer__row--second {
  align-items: flex-start;
}
html[data-sitetype=care] .footer .c-footer__row--second .c-footer__termslinks {
  margin-top: 0.5rem;
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

/*
*
*  Settings
*
*/
/*
*
*  The main mixin
*
*/
.headermenu {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  height: 36px;
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 999;
}
.headermenu__mainitems, .headermenu__subitems {
  list-style: none;
  padding: 0;
  margin: 0;
}
.headermenu__mainitems {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}
.headermenu__mainitem.hassubitems {
  position: relative;
}
.headermenu__mainitem.hassubitems::after {
  content: "";
  width: 8px;
  height: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 1;
  margin: 1px 0 0;
}
html.site-work .headermenu__mainlink .badge {
  background-color: #f17156;
  color: #fff;
}
.headermenu__mainitem {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.headermenu__mainitem + .headermenu__mainitem {
  margin-left: 23px;
}
.headermenu__subitems {
  position: absolute;
  z-index: 500;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  padding: 1rem;
  top: 36px;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -8px);
  transition: opacity 0.3s, transform 0.3s;
}
.headermenu__mainitem:hover > ul, .headermenu__mainitem.focus > ul {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, 0);
}
.headermenu .headermenu a {
  color: #fff !important;
}
.headermenu__sublink {
  display: block;
  text-decoration: none;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  position: relative;
  z-index: 35;
  pointer-events: none;
}
.header > * {
  pointer-events: auto;
}
.header .c-header {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 13vw), 0 100%);
  padding-bottom: 5rem;
  margin-bottom: 2rem;
}
.header .c-header__logocontainer, .header .c-header__menuinner {
  display: none;
  margin: 0 auto;
  width: 1640px;
  max-width: 100%;
}
@media screen and (max-width: 1640px) {
  .header .c-header__logocontainer, .header .c-header__menuinner {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (min-width: 1640px) {
  .header .c-header__logocontainer, .header .c-header__menuinner {
    max-width: 1226px;
    margin-left: auto !important;
    padding-right: 0 !important;
  }
  .header .c-header__logocontainer .c-header__logolink, .header .c-header__menuinner .c-header__logolink {
    display: inline-flex;
    transform: translateX(-100px);
  }
  .header .c-header__logocontainer .c-header__sites, .header .c-header__menuinner .c-header__sites {
    padding-left: 0;
  }
  .header .c-header__logocontainer .c-header__menuinner, .header .c-header__menuinner .c-header__menuinner {
    padding-right: 0;
  }
}
.header .c-header__menuinner {
  padding: 2.5rem 1rem 1.5rem;
  justify-content: space-between;
}
.header .c-header__searchicon {
  display: flex;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 100%;
  margin: 0 0 0 3rem;
  z-index: 10;
  position: relative;
  transform: rotate(0deg);
  transition: border 0.3s, transform 0.3s;
}
html.site-general .header .c-header__searchicon .icon-search .stroke {
  stroke: #d40f4d;
}
html.site-advice .header .c-header__searchicon .icon-search .stroke {
  stroke: #f17156;
}
html.site-work .header .c-header__searchicon .icon-search .stroke {
  stroke: #8fb8ec;
}
html.site-care .header .c-header__searchicon .icon-search .stroke {
  stroke: #4969f8;
}
@media (hover: hover) {
  .header .c-header__searchicon:hover {
    transform: rotate(90deg);
  }
}
.header .c-header__headingtitle {
  color: #fff;
  margin-top: 2.5rem;
}
.header .c-header__headingsubtitle {
  font-size: 30px;
  line-height: 38px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 400;
  color: #fff;
  margin-top: 2.5rem;
}
@media (min-width: 768px) {
  .header .c-header {
    padding-bottom: 10rem;
  }
}
@media (min-width: 1000px) {
  .header .c-header__logocontainer {
    display: block;
  }
  .header .c-header__menuinner {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .header .c-header {
    margin-bottom: 0;
  }
  .header .c-header__logocontainer {
    margin-left: 122px;
  }
  .header .c-header__menulink {
    margin-left: 1.5rem;
  }
  .header .c-header__menuinner {
    padding-right: 2.5rem;
    padding-left: 0;
  }
  .header .c-header__sites {
    padding-left: 122px;
  }
  .header .c-header__searchicon {
    margin-right: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .header .c-header {
    padding-bottom: 12rem;
  }
}
.header .c-header__sites a,
.header .headermenu .headermenu__mainlink {
  font-size: 17px;
  line-height: 100%;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  z-index: 0;
  transition: height 0.3s, background 0.3s, color 0.3s;
  color: #fff;
  margin: 0 1.5rem 0 0;
  font-weight: bold;
  text-decoration: none;
  text-transform: lowercase;
}
.header .c-header__sites a::after,
.header .headermenu .headermenu__mainlink::after {
  content: "";
  position: absolute;
  height: 1px;
  transition: height 0.2s, background 0.3s, color 0.3s, opacity 0.3s;
  bottom: -6px;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0;
}
@media (hover: hover) {
  .header .c-header__sites a:hover:after,
  .header .headermenu .headermenu__mainlink:hover:after {
    opacity: 1;
  }
}
.header .c-header__sites a.selected,
.header .headermenu .headermenu__mainlink.selected {
  text-decoration: none;
  display: inline-flex;
  position: relative;
  z-index: 0;
  transition: height 0.3s, background 0.3s, color 0.3s;
}
.header .c-header__sites a.selected::after,
.header .headermenu .headermenu__mainlink.selected::after {
  content: "";
  position: absolute;
  height: 1px;
  transition: height 0.2s, background 0.3s, color 0.3s, opacity 0.3s;
  bottom: -6px;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 1;
}
@media (hover: hover) {
  .header .c-header__sites a.selected:hover:after,
  .header .headermenu .headermenu__mainlink.selected:hover:after {
    opacity: 0;
  }
}
.header .c-header__sites a:after, .header .c-header__sites a.selected:after,
.header .headermenu .headermenu__mainlink:after,
.header .headermenu .headermenu__mainlink.selected:after {
  height: 2px !important;
}
.header .c-header__sites a:not(.hassubitems).selected::after,
.header .headermenu .headermenu__mainlink:not(.hassubitems).selected::after {
  opacity: 1;
}
.header .c-header__sites,
.header .headermenu {
  transition: opacity 0.2s;
}
.header .headermenu {
  flex: 1;
}
.header .headermenu .headermenu__mainlink {
  margin-right: 0;
  font-size: 20px;
  line-height: 100%;
  font-weight: bold;
}
.header .headermenu .headermenu__mainlink:after {
  bottom: -6px !important;
  height: 2px;
}
.header .headermenu .headermenu__mainlink.hassubitems:after {
  display: none !important;
}
.header .headermenu .headermenu__mainlink:not(.hassubitems) {
  text-decoration: none;
  display: inline-flex;
  position: relative;
  z-index: 0;
  transition: height 0.3s, background 0.3s, color 0.3s;
}
.header .headermenu .headermenu__mainlink:not(.hassubitems)::after {
  content: "";
  position: absolute;
  height: 1px;
  transition: height 0.2s, background 0.3s, color 0.3s, opacity 0.3s;
  bottom: -6px;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0;
}
@media (hover: hover) {
  .header .headermenu .headermenu__mainlink:not(.hassubitems):hover:after {
    opacity: 1;
  }
}
.header .headermenu__mainitem {
  padding-bottom: 30px;
  transform: translateY(15px);
}
.header .headermenu__subitems {
  padding: 0;
}
.header .headermenu__subitems:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 12px 10px;
  border-color: transparent transparent #fff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}
.header .headermenu__subitem:last-child .headermenu__sublink {
  border-bottom: 0;
}
.header .headermenu__sublink {
  font-size: 20px;
  line-height: 24px;
  padding: 20px 50px 20px 16px;
  border-bottom: 1px solid #c7c7c7;
  font-weight: normal;
  color: #606060 !important;
  transition: background 0.3s;
  position: relative;
}
@media (hover: hover) {
  .header .headermenu__sublink:hover {
    background-color: #eee;
  }
}
.header .headermenu__sublink-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
html.site-general .header .headermenu__sublink-icon .stroke {
  stroke: #d40f4d;
}
html.site-advice .header .headermenu__sublink-icon .stroke {
  stroke: #f17156;
}
html.site-work .header .headermenu__sublink-icon .stroke {
  stroke: #8fb8ec;
}
html.site-care .header .headermenu__sublink-icon .stroke {
  stroke: #4969f8;
}
.header--has-slideshow {
  min-height: 440px;
  padding-bottom: 0;
  margin-bottom: 250px;
}
.header--has-slideshow .slideshow {
  pointer-events: none;
  position: absolute;
  width: 100vw;
  z-index: 150;
  top: 110px;
  max-width: 100vw;
  margin-bottom: 0;
  top: -10px;
  z-index: 1;
}
.header--has-slideshow .slideshow > * {
  pointer-events: auto;
}
.header--has-slideshow .slideshow__item {
  height: 675px;
}
.header--has-slideshow .slideshow .slideshow__info {
  margin: 0 auto;
  max-width: 586px;
  width: 812px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 812px) {
  .header--has-slideshow .slideshow .slideshow__info {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.header--has-slideshow .slideshow .slideshow__info .slideshow__info-inner {
  transform: none;
  top: 0;
  max-width: 700px;
}
@media (min-width: 1000px) {
  .header--has-slideshow {
    min-height: 620px;
    margin-bottom: 130px;
  }
  .header--has-slideshow .slideshow {
    top: 140px;
  }
  .header--has-slideshow .c-header {
    min-height: 630px;
  }
}
@media (min-width: 1200px) {
  .header--has-slideshow .slideshow__item {
    height: 800px;
  }
  .header--has-slideshow .slideshow {
    top: 110px;
  }
  .header--has-slideshow .c-header {
    min-height: 730px;
  }
}
@media (min-width: 1400px) {
  .header--has-slideshow .slideshow {
    top: 90px;
  }
}
@media screen and (min-width: 2000px) {
  .header--has-slideshow {
    margin-bottom: 400px;
  }
  .header--has-slideshow .slideshow {
    top: 30px;
  }
  .header--has-slideshow .c-header {
    min-height: initial;
  }
  .header--has-slideshow .slideshow__item {
    height: 960px;
  }
}
@media screen and (min-width: 2500px) {
  .header--has-slideshow {
    margin-bottom: 300px;
  }
  .header--has-slideshow .slideshow {
    top: -10px;
  }
}
.header .c-header__searchcontainer {
  position: relative;
}
.header .c-header__searchform {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  position: absolute;
  top: -10px;
  right: -20px;
  width: 621px;
  height: 52px;
  z-index: 4;
  max-width: 90vw;
}
@media (min-width: 1200px) {
  .header .c-header__searchform {
    right: 25px;
  }
}

.headermenu__mainlink .badge {
  transition: opacity 0.3s;
}

html.showsearchinput .headermenu__mainlink .badge {
  opacity: 0;
  pointer-events: none;
}
html.showsearchinput .c-header__searchform {
  opacity: 1;
  pointer-events: auto;
}
html.showsearchinput .c-header__sites,
html.showsearchinput .headermenu {
  opacity: 0.2;
  pointer-events: none;
}

html.site-work .header .headermenu__mainitem + .headermenu__mainitem {
  margin-left: 14px;
}
html.site-work .header .headermenu__mainitem .headermenu__mainlink {
  font-size: 18px;
}
html.site-work .header .c-header__searchicon {
  margin-right: 0;
  margin-left: 1.5rem;
}
html.site-work .header .c-header__menuinner {
  padding-right: 1rem;
}

.ctatextlinks .ctatextlinks__link {
  font-size: 17px;
  line-height: 23px;
  display: flex;
  height: 100%;
  padding: 1rem 0;
  text-decoration: none;
  border-bottom: 1px solid #C7C7C7;
}
@media (min-width: 768px) {
  .ctatextlinks .ctatextlinks__link {
    padding-right: 0.5rem;
  }
}
.ctatextlinks .ctatextlinks__ctaicon {
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.ctatextlinks .ctatextlinks__icon {
  flex: 0 0 36px;
  display: flex;
  align-items: center;
}
.ctatextlinks .ctatextlinks__icon--fa .fa-map-marker-alt {
  font-size: 27px;
}
.ctatextlinks .ctatextlinks__icon--fa .fa-envelope {
  font-size: 22px;
}
.ctatextlinks .ctatextlinks__icon svg {
  display: block;
}
.ctatextlinks .ctatextlinks__title {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.ctatextlinks .ctatextlinks__title:not(.ctatextlinks__title--atgroephome) {
  font-weight: 700;
}
.ctatextlinks .ctatextlinks__title--gray {
  color: #606060 !important;
}
.ctatextlinks.ctatextlinks--addlinkhover .ctatextlinks__link > * {
  transition: transform 0.3s;
}
@media (hover: hover) {
  .ctatextlinks.ctatextlinks--addlinkhover:hover .ctatextlinks__link > * {
    transform: translateX(0.5rem);
  }
}
.ctatextlinks.ctatextlinks--firstbordertop .ctatextlinks__link:first-child {
  border-top: 1px solid #C7C7C7;
}
.ctatextlinks.ctatextlinks--big .ctatextlinks__icon {
  padding: 0 0 0 10px;
  font-size: 50px;
  white-space: nowrap;
  width: 50px;
  flex: 0 0 50px;
}
.ctatextlinks.ctatextlinks--big .ctatextlinks__title {
  padding-left: 25px;
  font-size: 20px;
  color: #606060 !important;
}
.ctatextlinks .ctatextlinks__title b {
  margin-right: 5px;
  font-size: 115%;
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.contact__griditem--phone {
  grid-area: contact__griditem--phone;
}

.contact__griditem--image {
  grid-area: contact__griditem--image;
}

.contact__griditem--contact {
  grid-area: contact__griditem--contact;
}

.contact__griditem--locations {
  grid-area: contact__griditem--locations;
}

.contact__grid {
  display: grid;
  gap: 0;
  grid-template-areas: "contact__griditem--image" "contact__griditem--locations" "contact__griditem--contact";
}
.contact__griditem:nth-child(2) {
  padding-right: 3rem;
}
.contact__griditem:nth-child(3) {
  margin-top: 4rem;
}
.contact__socials {
  display: flex;
  align-items: center;
  margin: 64px 0 0 0;
}
.contact__socials a {
  margin: 0 20px 0 0;
}
@media (hover: hover) {
  .contact__socials a:hover .fill {
    fill: #606060 !important;
  }
}
.contact__image {
  position: relative;
  top: -20px;
  left: -20px;
}
@media (min-width: 768px) {
  .contact__grid {
    grid-template-areas: "contact__griditem--image contact__griditem--locations" "contact__griditem--contact contact__griditem--contact";
  }
  .contact__griditem:nth-child(3) {
    margin-top: 0;
  }
  .contact .accordion__cardheadertitle {
    font-size: 35px;
    line-height: 100%;
  }
  .contact .accordion .card-body {
    padding-top: 0;
    margin: 0 0 15px;
  }
}
@media (min-width: 992px) {
  .contact__image {
    left: 0;
  }
  .contact__grid {
    grid-template-columns: 450px 1fr;
    grid-template-areas: "contact__griditem--image contact__griditem--locations" "contact__griditem--image contact__griditem--contact";
    gap: 4rem 0;
  }
}

.contact__image--customimages {
  position: relative;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.contact__customimage {
  display: block;
  aspect-ratio: 1;
  max-width: 100%;
}

@media (min-width: 768px) {
  .contact__image--customimages {
    max-width: 80%;
    gap: 2rem;
  }
  .contact__customimage {
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  }
  .contact__customimage--1 {
    aspect-ratio: 4/3;
  }
}
.contact__sponsors {
  margin: 3rem 0 0;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem 3rem;
  align-items: center;
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.w-employee__container, .w-employee__desktopcardcontainer {
  width: 1140px;
  max-width: 100%;
  margin: 0px auto;
}
.w-employee__desktopcardcontainer {
  position: relative;
}
.w-employee__container {
  padding: 5rem 0;
}
.w-employee__text, .w-employee__card {
  margin: 0 auto;
  max-width: 500px;
}
.w-employee__text {
  margin-bottom: 3rem;
}
.w-employee__text > * {
  color: #fff !important;
}
.w-employee__card--mobile {
  max-width: 350px;
}
.w-employee__card--desktop {
  display: none;
}
.w-employee .ctatextlinks--last .ctatextlinks__link {
  border-bottom: 0;
}
.w-employee .card__image {
  object-fit: cover;
  object-position: center;
  height: 210px;
}
.w-employee .card__titlecontainer {
  padding-top: 0;
}
@media (min-width: 768px) {
  .w-employee__container {
    display: flex;
  }
  .w-employee__text {
    order: 2;
    margin-left: 1rem;
  }
  .w-employee__card {
    margin-right: 1rem;
  }
}
@media (min-width: 992px) {
  .w-employee {
    margin-bottom: 100px;
  }
  .w-employee__container {
    position: relative;
    overflow: hidden;
    clip-path: polygon(0 calc(0% + 13vw), 100% 0, 100% calc(100% - 13vw), 0 100%);
    padding-top: 200px;
    padding-bottom: 160px;
  }
  .w-employee .card__image {
    height: auto;
  }
  .w-employee__text {
    margin-left: 2rem;
  }
  .w-employee__card {
    margin-right: 2rem;
  }
  .w-employee__card--mobile {
    opacity: 0;
    pointer-events: none;
    height: 460px;
    overflow: hidden;
  }
  .w-employee__card--desktop {
    display: block;
    position: absolute;
    top: 260px;
    right: 565px;
    z-index: 10;
    margin: 0 110px 0 0;
    width: 350px;
  }
}
@media (min-width: 1400px) {
  .w-employee__container {
    position: relative;
    overflow: hidden;
    clip-path: polygon(0 calc(0% + 9vw), 100% 0, 100% calc(100% - 9vw), 0 100%);
  }
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.w-floatingquote {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}
.w-floatingquote__inner {
  margin: 1.5rem auto;
  max-width: 600px;
}
@media (min-width: 768px) {
  .w-floatingquote__inner {
    margin: 2rem auto;
  }
}
@media (min-width: 992px) {
  .w-floatingquote__inner {
    float: right;
    margin: 2rem 1rem 2rem 2rem;
  }
  .w-floatingquote blockquote {
    margin: 0 auto;
    max-width: 570px;
  }
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.widget--iconlist .ctatextlinks.ctatextlinks--big .ctatextlinks__title {
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
}
.widget--iconlist svg.icon-marker {
  width: 100%;
  transform: scale(1.3);
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.w-imagewithcaption {
  position: relative;
}
.w-imagewithcaption__caption {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
  padding: 1.5rem 1.5rem 2.5rem;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.16);
  margin: 0 0 1rem;
  transform: translateY(-1.5rem);
}
.w-imagewithcaption__caption * {
  font-size: 15px;
  line-height: 20px;
  color: #fff;
}
@media (min-width: 576px) {
  .w-imagewithcaption__caption {
    padding-bottom: 4rem;
  }
}
@media (min-width: 992px) {
  .w-imagewithcaption {
    margin-top: 5rem;
  }
  .w-imagewithcaption__caption {
    position: relative;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2vw), 0 100%);
    padding-bottom: 3rem;
    margin: 0;
    transform: none;
    position: absolute;
    top: -2rem;
    left: -2rem;
    max-width: 270px;
  }
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.w-staff .card__buttoncontainer,
.w-staff .card__description {
  display: none;
}
.w-staff .card__title {
  font-size: 25px;
  line-height: 100%;
}
.w-staff .card .ctatextlinks--last .ctatextlinks__link {
  border-bottom: 0;
}
.w-staff .p-overview__filters {
  display: block !important;
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.w-textimage {
  position: relative;
}
.w-textimage .col--image {
  padding-bottom: 30px;
}
@media (min-width: 576px) {
  @supports (display: grid) {
    .w-textimage .container, .w-textimage .container-fluid {
      margin: 0 auto;
      width: 1364px;
      max-width: 100%;
    }
    .w-textimage .container > .row, .w-textimage .container-fluid > .row {
      display: grid;
      max-width: 100%;
      margin: 0;
    }
    .w-textimage .container > .row > .col[class*=p], .w-textimage .container-fluid > .row > .col[class*=p] {
      padding: 0 !important;
    }
    .w-textimage .container > .row > .col[class*=m], .w-textimage .container-fluid > .row > .col[class*=m] {
      margin: 0 !important;
    }
    .w-textimage .container div[class*=row-] > *, .w-textimage .container-fluid div[class*=row-] > * {
      flex: none !important;
      max-width: 100%;
    }
    @media screen and (min-width: 576px) and (max-width: 1364px) {
      .w-textimage .container, .w-textimage .container-fluid {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    .w-textimage .container .row, .w-textimage .container-fluid .row {
      grid-gap: 40px 70px;
      grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    }
  }
}
.w-textimage__background {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 13vw), 100% 0, 100% calc(100% - 13vw), 0 100%);
  position: absolute;
  bottom: -400px;
  top: -200px;
  left: 0;
  right: 0;
  background: #ecf3fc;
  z-index: -1;
}
@media (min-width: 1200px) {
  .w-textimage .widget.widget--iconlist.widget--site {
    max-width: 355px;
  }
}
.w-textimage .c-imagegrid .c-imagegrid__content {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
}
.w-textimage .c-imagegrid .c-imagegrid__content > div:nth-child(1) {
  width: 100%;
}
.w-textimage .c-imagegrid .c-imagegrid__content > div:nth-child(2),
.w-textimage .c-imagegrid .c-imagegrid__content > div:nth-child(3) {
  width: calc(50% - 4px);
  aspect-ratio: 3/4;
}
.w-textimage .c-imagegrid .c-imagegrid__content img {
  display: block;
  width: 100%;
  height: auto;
  filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.16));
}
@media (min-width: 768px) {
  .w-textimage .c-imagegrid .c-imagegrid__content {
    max-width: 600px;
    gap: 0;
  }
  .w-textimage .c-imagegrid .c-imagegrid__content > div:nth-child(1) {
    aspect-ratio: 525/339;
    padding: 40px;
    max-height: unset;
  }
  .w-textimage .c-imagegrid .c-imagegrid__content > div:nth-child(2),
  .w-textimage .c-imagegrid .c-imagegrid__content > div:nth-child(3) {
    width: 50%;
  }
  .w-textimage .c-imagegrid .c-imagegrid__content > div:nth-child(2) {
    padding-right: 16px;
  }
  .w-textimage .c-imagegrid .c-imagegrid__content > div:nth-child(2) img {
    transform: translate(-24px, -80px);
  }
  .w-textimage .c-imagegrid .c-imagegrid__content > div:nth-child(3) {
    padding-left: 16px;
  }
  .w-textimage .c-imagegrid .c-imagegrid__content > div:nth-child(3) img {
    transform: scale(0.7) translate(-100px, -120px);
  }
}

.w-twocolumnstext__col {
  margin-bottom: 16px;
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.w-vacancies {
  margin: 0 auto;
  width: 1088px;
  max-width: 100%;
}
@media screen and (max-width: 1088px) {
  .w-vacancies {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.w-vacancies__title {
  text-align: center;
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after, html.site-general p.normal a:after, p.normal html.site-general a:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after, html.site-advice p.normal a:after, p.normal html.site-advice a:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after, html.site-work p.normal a:after, p.normal html.site-work a:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after, html.site-care p.normal a:after, p.normal html.site-care a:after {
  background: #4969f8;
}

.w-why {
  position: relative;
}
.w-why__title {
  font-size: 61px;
  line-height: 100%;
  font-weight: bold;
  color: #97c0ef;
  margin: 0 0 72px;
}
.w-why .ctatextlinks .ctatextlinks__link {
  border-color: #c7c7c7 !important;
}
.w-why .ctatextlinks__link {
  margin: 0 56px 0 0;
}
.w-why .ctatextlinks.ctatextlinks--big .ctatextlinks__icon {
  padding: 0;
  width: 72px;
  flex: 0 0 72px;
}
.w-why .ctatextlinks.ctatextlinks--big .ctatextlinks__title {
  font-size: 15px;
  line-height: 20px;
  padding-left: 0;
}
@media screen and (min-width: 900px) {
  .w-why .ctatextlinks--first {
    padding-top: 32px;
  }
}
.w-why .c-block {
  position: relative;
  border-right: 2px solid transparent;
}
.w-why .c-block__title {
  font-size: 34px;
  line-height: 100%;
  font-weight: bold;
  position: absolute;
  top: 83px;
  right: -72px;
  transform-origin: center;
  transform: rotate(-90deg);
  text-align: right;
  width: 200px;
}
.w-why .c-block__item {
  padding: 24px 0 0 0;
  border-right: 2px solid transparent;
}
.w-why .c-block__item--first {
  padding-top: 0;
}
@media screen and (min-width: 900px) {
  .w-why .c-block__item--first {
    padding-top: 48px;
  }
}
.w-why .c-block__inner {
  margin: 0 56px 0 0;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #c7c7c7 !important;
}
.w-why .c-block__icon {
  padding: 0 0 16px;
  width: 88px;
}
.w-why .c-block__text {
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  margin-left: 8px;
}
.w-why__small {
  display: flex;
  flex-flow: row wrap;
}
.w-why__small .theme-fill {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px;
}
.w-why__small .c-block {
  margin-bottom: 2rem;
}
.w-why__small .c-block__icon {
  padding: 0;
  display: flex;
  justify-content: center;
}
.w-why__small .c-block.marker {
  font-size: 50px;
}
@media (min-width: 768px) {
  .w-why__small .theme-fill {
    width: calc(50% - 20px);
  }
}
@media (min-width: 992px) {
  .w-why__small .theme-fill {
    width: calc(30% - 20px);
  }
}
@media (min-width: 1200px) {
  .w-why__small {
    justify-content: space-between;
  }
  .w-why__small .theme-fill {
    width: calc(20% - 20px);
    margin: 0 10px;
  }
  .w-why__small .c-block__icon {
    justify-content: flex-start;
  }
}
@media (min-width: 576px) {
  @supports (display: grid) {
    .w-why .container, .w-why .container-fluid {
      margin: 0 auto;
      width: 1364px;
      max-width: 100%;
    }
    .w-why .container > .row, .w-why .container-fluid > .row {
      display: grid;
      max-width: 100%;
      margin: 0;
    }
    .w-why .container > .row > .col[class*=p], .w-why .container-fluid > .row > .col[class*=p] {
      padding: 0 !important;
    }
    .w-why .container > .row > .col[class*=m], .w-why .container-fluid > .row > .col[class*=m] {
      margin: 0 !important;
    }
    .w-why .container div[class*=row-] > *, .w-why .container-fluid div[class*=row-] > * {
      flex: none !important;
      max-width: 100%;
    }
    @media screen and (min-width: 576px) and (max-width: 1364px) {
      .w-why .container, .w-why .container-fluid {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    .w-why .container .row, .w-why .container-fluid .row {
      grid-gap: 64px 56px;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
  }
}
@supports not (display: grid) {
  @media (min-width: 992px) {
    .w-why .container > .row {
      justify-content: space-between;
    }
    .w-why .container > .row > .col {
      max-width: calc(50% - 24px);
      margin-bottom: 4rem;
    }
  }
  @media (min-width: 1200px) {
    .w-why .container > .row > .col {
      max-width: calc(33% - 24px);
    }
  }
}

html.site-general {
  background-color: #d40f4d;
}

html.site-advice {
  background-color: #f17156;
}

html.site-work {
  background-color: #8fb8ec;
}

html.site-care {
  background-color: #4969f8;
}

body,
*[data-barba=container] {
  opacity: 0;
}
html.initialized body,
html.initialized *[data-barba=container] {
  opacity: 1;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: var(--body-padding-top);
  transition: opacity 0.2s;
}
body main {
  flex: 1;
}

@media (min-width: 1000px) {
  body {
    padding-top: 0;
  }
  .topbar,
  .topbar__mobilesearchform {
    display: none;
  }
}
main {
  padding-top: 0;
}

.griddebug {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
  width: 1640px;
  z-index: 999999999;
}
.griddebug__col {
  background: rgba(0, 255, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 122px;
}
.griddebug__col:nth-child(1) {
  left: 0px;
}
.griddebug__col:nth-child(2) {
  left: 138px;
}
.griddebug__col:nth-child(3) {
  left: 276px;
}
.griddebug__col:nth-child(4) {
  left: 414px;
}
.griddebug__col:nth-child(5) {
  left: 552px;
}
.griddebug__col:nth-child(6) {
  left: 690px;
}
.griddebug__col:nth-child(7) {
  left: 828px;
}
.griddebug__col:nth-child(8) {
  left: 966px;
}
.griddebug__col:nth-child(9) {
  left: 1104px;
}
.griddebug__col:nth-child(10) {
  left: 1242px;
}
.griddebug__col:nth-child(11) {
  left: 1380px;
}
.griddebug__col:nth-child(12) {
  left: 1518px;
}

html .mobile-wrapper-padding {
  padding-left: 16px;
  padding-right: 16px;
}

wh-outputtools {
  opacity: 1 !important;
}

.skewedblock {
  height: 250px;
  background: #d40f4d !important;
  position: relative;
}
.skewedblock:after {
  overflow: hidden;
  content: "";
  position: absolute;
  background: #fff;
  left: 0;
  transform: skewY(-8.4deg);
  height: 200%;
  transform-origin: bottom left;
  width: 100%;
  top: 100%;
}
.skewedblock ~ * {
  position: relative;
}

svg * {
  transition: stroke 0.3s, fill 0.3s;
}

.contentcontainer {
  width: 812px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 812px) {
  .contentcontainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
main {
  min-height: 400px;
}
main > * {
  position: relative;
  z-index: 1;
}
main > h1,
main > h2,
main > h3,
main > h4,
main > p.normal,
main > ul,
main > ol,
main > .widget--download,
main > .widget--button,
main > .widget--twocolumnstext,
main > .wh-video[data-video],
main > .widget--formwidget, html.page-form main form.wh-form,
main > .wh-rtd__tablewrap {
  width: 812px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 812px) {
  main > h1,
  main > h2,
  main > h3,
  main > h4,
  main > p.normal,
  main > ul,
  main > ol,
  main > .widget--download,
  main > .widget--button,
  main > .widget--twocolumnstext,
  main > .wh-video[data-video],
  main > .widget--formwidget, html.page-form main form.wh-form,
  main > .wh-rtd__tablewrap {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
html.wh-errorpage--404 .crumbpath {
  display: none;
}

.pageloadoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 9999999;
  transition: background 0.4s;
  pointer-events: none;
  will-transform: opacity;
}
.pageloadoverlay__logocontainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.1s ease;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.pageloadoverlay .pageloadoverlay__logocontainer.flip .pageloadoverlay__logoinner {
  transform: rotateY(180deg);
}
.pageloadoverlay__logoinner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.pageloadoverlay__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  display: none;
}
.pageloadoverlay__logo.active {
  display: inline;
}
.pageloadoverlay .pageloadoverlay__logo--back {
  transform: translate(-50%, -50%) rotateY(180deg);
}

.theme-opacity {
  opacity: 0;
  pointer-events: none;
}

html.site-general .theme-opacity--general {
  opacity: 1;
  pointer-events: auto;
}

html.site-advice .theme-opacity--advice {
  opacity: 1;
  pointer-events: auto;
}

html.site-work .theme-opacity--work {
  opacity: 1;
  pointer-events: auto;
}

html.site-care .theme-opacity--care {
  opacity: 1;
  pointer-events: auto;
}

.type-general--fill, .type-general--fill * {
  fill: #d40f4d !important;
}
.type-general--fill.lighten, .type-general--fill.lighten * {
  fill: #e71054 !important;
}
.type-general--fill.darken, .type-general--fill.darken * {
  fill: #c60e48 !important;
}
.type-general--stroke, .type-general--stroke * {
  stroke: #d40f4d !important;
}
.type-general--stroke.lighten, .type-general--stroke.lighten * {
  stroke: #e71054 !important;
}
.type-general--stroke.darken, .type-general--stroke.darken * {
  stroke: #c60e48 !important;
}
.type-general--color, .type-general--color * {
  color: #d40f4d !important;
}
.type-general--color.lighten, .type-general--color.lighten * {
  color: #e71054 !important;
}
.type-general--color.darken, .type-general--color.darken * {
  color: #c60e48 !important;
}
.type-general--border-color, .type-general--border-color * {
  border-color: #d40f4d !important;
}
.type-general--border-color.lighten, .type-general--border-color.lighten * {
  border-color: #e71054 !important;
}
.type-general--border-color.darken, .type-general--border-color.darken * {
  border-color: #c60e48 !important;
}
.type-general--background-color, .type-general--background-color * {
  background-color: #d40f4d !important;
}
.type-general--background-color.lighten, .type-general--background-color.lighten * {
  background-color: #e71054 !important;
}
.type-general--background-color.darken, .type-general--background-color.darken * {
  background-color: #c60e48 !important;
}

.type-advice--fill, .type-advice--fill * {
  fill: #f17156 !important;
}
.type-advice--fill.lighten, .type-advice--fill.lighten * {
  fill: #f38169 !important;
}
.type-advice--fill.darken, .type-advice--fill.darken * {
  fill: #f06548 !important;
}
.type-advice--stroke, .type-advice--stroke * {
  stroke: #f17156 !important;
}
.type-advice--stroke.lighten, .type-advice--stroke.lighten * {
  stroke: #f38169 !important;
}
.type-advice--stroke.darken, .type-advice--stroke.darken * {
  stroke: #f06548 !important;
}
.type-advice--color, .type-advice--color * {
  color: #f17156 !important;
}
.type-advice--color.lighten, .type-advice--color.lighten * {
  color: #f38169 !important;
}
.type-advice--color.darken, .type-advice--color.darken * {
  color: #f06548 !important;
}
.type-advice--border-color, .type-advice--border-color * {
  border-color: #f17156 !important;
}
.type-advice--border-color.lighten, .type-advice--border-color.lighten * {
  border-color: #f38169 !important;
}
.type-advice--border-color.darken, .type-advice--border-color.darken * {
  border-color: #f06548 !important;
}
.type-advice--background-color, .type-advice--background-color * {
  background-color: #f17156 !important;
}
.type-advice--background-color.lighten, .type-advice--background-color.lighten * {
  background-color: #f38169 !important;
}
.type-advice--background-color.darken, .type-advice--background-color.darken * {
  background-color: #f06548 !important;
}

.type-work--fill, .type-work--fill * {
  fill: #8fb8ec !important;
}
.type-work--fill.lighten, .type-work--fill.lighten * {
  fill: #a0c3ef !important;
}
.type-work--fill.darken, .type-work--fill.darken * {
  fill: #82b0ea !important;
}
.type-work--stroke, .type-work--stroke * {
  stroke: #8fb8ec !important;
}
.type-work--stroke.lighten, .type-work--stroke.lighten * {
  stroke: #a0c3ef !important;
}
.type-work--stroke.darken, .type-work--stroke.darken * {
  stroke: #82b0ea !important;
}
.type-work--color, .type-work--color * {
  color: #8fb8ec !important;
}
.type-work--color.lighten, .type-work--color.lighten * {
  color: #a0c3ef !important;
}
.type-work--color.darken, .type-work--color.darken * {
  color: #82b0ea !important;
}
.type-work--border-color, .type-work--border-color * {
  border-color: #8fb8ec !important;
}
.type-work--border-color.lighten, .type-work--border-color.lighten * {
  border-color: #a0c3ef !important;
}
.type-work--border-color.darken, .type-work--border-color.darken * {
  border-color: #82b0ea !important;
}
.type-work--background-color, .type-work--background-color * {
  background-color: #8fb8ec !important;
}
.type-work--background-color.lighten, .type-work--background-color.lighten * {
  background-color: #a0c3ef !important;
}
.type-work--background-color.darken, .type-work--background-color.darken * {
  background-color: #82b0ea !important;
}

.type-care--fill, .type-care--fill * {
  fill: #4969f8 !important;
}
.type-care--fill.lighten, .type-care--fill.lighten * {
  fill: #5d79f9 !important;
}
.type-care--fill.darken, .type-care--fill.darken * {
  fill: #3a5df7 !important;
}
.type-care--stroke, .type-care--stroke * {
  stroke: #4969f8 !important;
}
.type-care--stroke.lighten, .type-care--stroke.lighten * {
  stroke: #5d79f9 !important;
}
.type-care--stroke.darken, .type-care--stroke.darken * {
  stroke: #3a5df7 !important;
}
.type-care--color, .type-care--color * {
  color: #4969f8 !important;
}
.type-care--color.lighten, .type-care--color.lighten * {
  color: #5d79f9 !important;
}
.type-care--color.darken, .type-care--color.darken * {
  color: #3a5df7 !important;
}
.type-care--border-color, .type-care--border-color * {
  border-color: #4969f8 !important;
}
.type-care--border-color.lighten, .type-care--border-color.lighten * {
  border-color: #5d79f9 !important;
}
.type-care--border-color.darken, .type-care--border-color.darken * {
  border-color: #3a5df7 !important;
}
.type-care--background-color, .type-care--background-color * {
  background-color: #4969f8 !important;
}
.type-care--background-color.lighten, .type-care--background-color.lighten * {
  background-color: #5d79f9 !important;
}
.type-care--background-color.darken, .type-care--background-color.darken * {
  background-color: #3a5df7 !important;
}

.swiper-button-next,
.swiper-button-prev {
  margin-top: 0;
}

.table.table-head tbody > tr:first-child td,
.table.table-head tbody > tr:first-child th {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.table.table-head tbody > tr:first-child td > *,
.table.table-head tbody > tr:first-child th > * {
  color: #fff;
}

.table.responsive_table {
  word-break: break-word;
}

.table.stacktable {
  max-width: 100%;
  display: none;
}
.table.stacktable.small-only {
  display: block;
}
@media (min-width: 768px) {
  .table.stacktable {
    word-break: initial;
  }
  .table.stacktable.small-only {
    display: none;
  }
  .table.stacktable.large-only {
    display: block;
  }
}
.table.stacktable .st-head-row {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.table.stacktable th > *:last-child,
.table.stacktable td > *:last-child {
  margin-bottom: 0;
}

.djopzzheader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 350;
  min-height: var(--djopzz-header-height);
  background-color: #fff;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.djopzzheader__container {
  position: relative;
  width: 100%;
}

.djopzzheader__payoff {
  font-size: 20px;
  line-height: 110%;
  font-style: italic;
  font-weight: bold;
  padding: 1rem 3rem 1rem 1rem;
  color: var(--djopzz-main-color);
}
.djopzzheader__payoff a {
  color: inherit !important;
  text-decoration: none;
}
.djopzzheader__payoff img {
  margin-left: 8px;
}

.djopzzheader__lines {
  display: none;
  position: absolute;
  top: 0;
  right: -12vw;
  pointer-events: none;
}

.djopzzheader__close {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%) scale(0.85);
}
.djopzzheader__close .icon-close .stroke {
  stroke: var(--djopzz-main-color);
}

.djopzzheader__container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
}

@media (min-width: 1000px) {
  .djopzzheader {
    --djopzz-header-height: 120px;
    position: relative;
    overflow: hidden;
  }
  .djopzzheader__payoff {
    font-size: 24px;
  }
  .djopzzheader__lines {
    display: unset;
    right: -5vw;
  }
  .djopzzheader__close {
    right: 32px;
  }
}
@media (min-width: 1200px) {
  .djopzzheader__payoff {
    margin-left: 122px;
  }
  .djopzzheader__lines {
    right: 0vw;
  }
  .djopzzheader__close {
    right: 91px;
  }
}
@media screen and (min-width: 1640px) {
  .djopzzheader__container {
    max-width: 1500px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  .djopzzheader__close {
    right: 180px;
  }
  .djopzzheader__lines {
    right: 100px;
  }
}
html.has-djopzz-header body {
  --body-padding-top: calc(
    var(--topbar-height) + var(--djopzz-header-height)
  );
}
html.has-djopzz-header .djopzzheader {
  display: flex;
}
html.has-djopzz-header .topbar {
  top: var(--djopzz-header-height);
}