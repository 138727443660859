.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.wh-form {
  /* IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance, IE 9 and earlier get a native select - targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting */
  /* The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
}
.wh-form select {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2216%22%20height%3D%228%22%20viewBox%3D%220%200%2016%208%22%3E%3Cpolygon%20fill%3D%22%234D4D4D%22%20points%3D%228%2C0%2016%2C0%2012%2C4%208%2C8%204%2C4%200%2C0%20%22/%3E%3C/svg%3E);
  background-repeat: no-repeat;
  background-position: 95% 50%;
  border: 1px solid transparent;
  border-radius: 0;
  width: 100%;
  padding: 10px 12px;
  height: 50px;
}
html.site-general .wh-form select {
  border-color: #d40f4d;
}
html.site-advice .wh-form select {
  border-color: #f17156;
}
html.site-work .wh-form select {
  border-color: #8fb8ec;
}
html.site-care .wh-form select {
  border-color: #4969f8;
}
.wh-form select:focus, .wh-form select:hover {
  border-color: black;
  outline: none;
}
.wh-form select:active {
  border-color: #900;
}
.wh-form select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .wh-form select::-ms-expand {
    display: none;
  }
  .wh-form select:focus::-ms-value {
    background: transparent;
    color: grey;
  }
}

.wh-form__textinput, .wh-form__textarea, .wh-form__uploadfieldfilename {
  width: 100%;
  border: 1px solid #8C8C8C;
  padding: 10px 1.5rem;
  transition: border-color 0.3s;
  outline: none;
  font-size: 16px;
  border-radius: 26px;
}
.wh-form__textinput:focus, .wh-form__textarea:focus, .wh-form__uploadfieldfilename:focus {
  border-color: #175FC7;
}
.wh-form__textarea {
  min-height: 120px;
  display: block;
  font-family: "Arial";
}
.wh-form__fieldgroup--error .wh-form__textinput,
.wh-form__fieldgroup--error .wh-form__textarea {
  border-color: #ff5252;
}
.wh-form__fieldgroup--error .wh-form__label,
.wh-form__fieldgroup--error .wh-form__textinput,
.wh-form__fieldgroup--error .wh-form__textarea {
  color: #ff5252;
}
.wh-form__fieldgroup--success .wh-form__textinput,
.wh-form__fieldgroup--success .wh-form__textarea {
  border-color: green;
}
.wh-form input[type=date] {
  max-width: 200px;
}
.wh-form input[type=time] {
  max-width: 100px;
}
.wh-form input[type=number] {
  max-width: 150px;
}

.wh-form label {
  margin-bottom: 0;
}

.wh-form__error {
  position: absolute;
  color: #ff5252;
  font-size: 90%;
  transform: translateY(-2px);
}

.wh-form__fieldgroup {
  margin: 0 0 2rem;
}

.wh-form__dateinputgroup {
  display: inline-flex;
  border: 1px solid #8C8C8C;
  border-radius: 26px;
}
.wh-form__dateinputgroup .wh-form__dateinputgroup__line input, .wh-form__dateinputgroup .wh-form__timeinputgroup__line input {
  background: none !important;
}

.wh-form__fieldgroup--required label.wh-form__label::after,
.wh-form__fieldgroup--required.wh-form__fieldgroup--checkbox .wh-form__optionlabel::after {
  content: "*";
}

.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline > label,
.wh-form__fieldgroup--checkboxgroup .wh-form__optionlabel {
  cursor: pointer;
}
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  margin-bottom: 0.5rem;
}
.wh-form__fieldgroup--checkboxgroup .wh-form__optionlabel {
  margin-left: 6px;
}

.wh-form:not(.wh-form--allowprevious) .wh-form__navbuttons {
  display: flex;
  justify-content: flex-end;
}

.wh-form.wh-form--allowprevious .wh-form__navbuttons {
  display: flex;
  justify-content: space-between;
}

.wh-form__uploadfield .wh-form__button {
  padding-left: 30px;
  padding-right: 30px;
}
.wh-form__uploadfield .button__arrowright {
  display: none;
}

.wh-form__button.wh-form__button--previous {
  justify-content: flex-end;
}

.wh-form__button--previous .button__label {
  transform: translateX(22px);
}

.wh-form__button--previous .button__arrowright {
  right: auto;
  left: 22px;
  transform: translateY(-50%) scaleX(-1);
}

.wh-form__uploadfieldselect {
  margin: 1rem 0 0;
}

.wh-form__uploadfielddelete {
  background: none;
  color: #000;
  font-size: 100%;
  top: -10px;
  right: 14px;
  cursor: pointer;
}

.wh-form__fieldgroup.hidden {
  display: none;
}

.wh-form--radiogroup input[type=radio] {
  position: absolute;
  left: -9999px;
}
.wh-form--radiogroup input[type=radio] + label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: 0;
}
.wh-form--radiogroup input[type=radio] + label + span label {
  position: relative;
  top: 4px;
  margin-left: 29px;
  white-space: nowrap;
}
.wh-form--radiogroup input[type=radio] + label::before {
  content: "";
  border: 1px solid #000;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: -12px;
  border-radius: 50%;
}
.wh-form--radiogroup input[type=radio] + label::after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: -9px;
  left: 3px;
  background: #000;
  opacity: 0;
  border-radius: 50%;
  transition: opacity 0.3s;
}
.wh-form--radiogroup input[type=radio]:checked + label::after {
  opacity: 1;
}

.wh-form__fieldgroup--radiogroup input[type=radio] {
  position: absolute;
  left: -9999px;
}
.wh-form__fieldgroup--radiogroup input[type=radio] + label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: 0;
}
.wh-form__fieldgroup--radiogroup input[type=radio] + label + span label {
  position: relative;
  top: 4px;
  margin-left: 29px;
  white-space: nowrap;
}
.wh-form__fieldgroup--radiogroup input[type=radio] + label::before {
  content: "";
  border: 1px solid #4969F8;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: -12px;
  border-radius: 50%;
}
.wh-form__fieldgroup--radiogroup input[type=radio] + label::after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: -9px;
  left: 3px;
  background: #4969F8;
  opacity: 0;
  border-radius: 50%;
  transition: opacity 0.3s;
}
.wh-form__fieldgroup--radiogroup input[type=radio]:checked + label::after {
  opacity: 1;
}

.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  margin-bottom: 10px;
}
.wh-form__fieldgroup--checkboxgroup input[type=checkbox] {
  position: absolute;
  left: -9999px;
}
.wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: 0;
}
.wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label + span label {
  position: relative;
  top: 4px;
  margin-left: 29px;
  white-space: nowrap;
}
.wh-form--small-checkboxes .wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label + span label {
  margin-left: 22px;
  top: -2px;
}
.wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label::before {
  content: "";
  border: 1px solid transparent;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: -12px;
  border-radius: 5px;
}
html.site-general .wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label::before {
  border-color: #d40f4d;
}
html.site-advice .wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label::before {
  border-color: #f17156;
}
html.site-work .wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label::before {
  border-color: #8fb8ec;
}
html.site-care .wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label::before {
  border-color: #4969f8;
}
.wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label::after {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  top: -7px;
  left: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 2px;
}
html.site-general .wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label::after {
  background-color: #d40f4d;
}
html.site-advice .wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label::after {
  background-color: #f17156;
}
html.site-work .wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label::after {
  background-color: #8fb8ec;
}
html.site-care .wh-form__fieldgroup--checkboxgroup input[type=checkbox] + label::after {
  background-color: #4969f8;
}
.wh-form__fieldgroup--checkboxgroup input[type=checkbox]:checked + label::after {
  opacity: 1;
}

.wh-form__optionlabel {
  display: inline-block;
  cursor: pointer;
}