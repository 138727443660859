.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.contact__griditem--phone {
  grid-area: contact__griditem--phone;
}

.contact__griditem--image {
  grid-area: contact__griditem--image;
}

.contact__griditem--contact {
  grid-area: contact__griditem--contact;
}

.contact__griditem--locations {
  grid-area: contact__griditem--locations;
}

.contact__grid {
  display: grid;
  gap: 0;
  grid-template-areas: "contact__griditem--image" "contact__griditem--locations" "contact__griditem--contact";
}
.contact__griditem:nth-child(2) {
  padding-right: 3rem;
}
.contact__griditem:nth-child(3) {
  margin-top: 4rem;
}
.contact__socials {
  display: flex;
  align-items: center;
  margin: 64px 0 0 0;
}
.contact__socials a {
  margin: 0 20px 0 0;
}
@media (hover: hover) {
  .contact__socials a:hover .fill {
    fill: #606060 !important;
  }
}
.contact__image {
  position: relative;
  top: -20px;
  left: -20px;
}
@media (min-width: 768px) {
  .contact__grid {
    grid-template-areas: "contact__griditem--image contact__griditem--locations" "contact__griditem--contact contact__griditem--contact";
  }
  .contact__griditem:nth-child(3) {
    margin-top: 0;
  }
  .contact .accordion__cardheadertitle {
    font-size: 35px;
    line-height: 100%;
  }
  .contact .accordion .card-body {
    padding-top: 0;
    margin: 0 0 15px;
  }
}
@media (min-width: 992px) {
  .contact__image {
    left: 0;
  }
  .contact__grid {
    grid-template-columns: 450px 1fr;
    grid-template-areas: "contact__griditem--image contact__griditem--locations" "contact__griditem--image contact__griditem--contact";
    gap: 4rem 0;
  }
}

.contact__image--customimages {
  position: relative;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.contact__customimage {
  display: block;
  aspect-ratio: 1;
  max-width: 100%;
}

@media (min-width: 768px) {
  .contact__image--customimages {
    max-width: 80%;
    gap: 2rem;
  }
  .contact__customimage {
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  }
  .contact__customimage--1 {
    aspect-ratio: 4/3;
  }
}
.contact__sponsors {
  margin: 3rem 0 0;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem 3rem;
  align-items: center;
}