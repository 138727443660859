.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.card {
  border: 0;
  display: flex;
  text-decoration: none;
  box-shadow: -20px 20px 30px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border-radius: 0;
}

.card__titlecontainer {
  display: flex;
  color: #fff;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 2rem;
  position: relative;
}
.card__titlecontainer--bgimage {
  min-height: 290px;
  position: relative;
}
.card__titlecontainer--bgimage:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
  z-index: -1;
}
.card__titlecontainer--bgimage .card__title,
.card__titlecontainer--bgimage .card__subtitle {
  padding: 0 2rem;
  max-width: 430px;
}
.card__titlecontainer--bgimage .card__title {
  font-size: 41px;
  line-height: 100%;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: 123px; /* Fallback for non-webkit */
  font-size: 41px !important;
  line-height: 1 !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
}
.card__titlecontainer--bgimage .card__subtitle {
  font-size: 15px;
  line-height: 20px;
}
.card__titlecontainer--skewbottom {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2vw), 0 100%);
  padding: 0 0 3rem;
}
.card__titlecontainer--haslinkedin .card__title, .card__titlecontainer--haslinkedin .card__subtitle {
  padding-right: 64px;
}

.card__skewedbackground {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 2vw), 100% 0, 100% 100%, 0 100%);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  top: -2rem;
}
@media screen and (min-width: 1600px) {
  .card__skewedbackground {
    top: -4rem;
  }
}

.card__subtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.card__toptitle,
.card__title,
.card__subtitle,
.card__description,
.card__ctas {
  padding: 0 2rem;
  padding: 0 1.5rem;
  position: relative;
  z-index: 1;
}

.card__toptitle {
  font-size: 25px;
  line-height: 28px;
  font-weight: bold;
  color: #fff;
  padding-top: 1.5rem;
}
.card__toptitle + .card__title {
  padding-top: 1rem;
}

.card__ctas {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.card__title {
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  position: relative;
  top: -1px;
}
.card__title + .card__subtitle {
  margin-top: 12px;
}
.card__title--noimage {
  padding-top: 2rem;
}

.card__description {
  margin: 1rem 0 2rem;
  color: #606060;
}

.card__buttoncontainer {
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

.card__share {
  text-align: center;
}

.card__sharetext {
  font-size: 17px;
  line-height: 100%;
  margin-top: 16px;
}

.card__socials {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0 2rem;
}
.card__socials a {
  margin: 0 1rem;
}
@media (hover: hover) {
  .card__socials a:hover .fill {
    fill: #606060 !important;
  }
}

.card__linkedin {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 10;
}
.card__linkedin .social-linkedin .fill {
  fill: #fff;
}

.card .ctatextlinks__link {
  font-size: 17px;
  line-height: 30px;
  font-weight: normal;
  color: #606060;
}