.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.topbar {
  z-index: 350;
  border-bottom: 1px solid #e8ebee;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.topbar__container {
  align-items: center;
  height: var(--topbar-height);
  padding-left: 14px;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
}
.topbar__right {
  display: flex;
  align-items: center;
}
.topbar__logoimg img {
  display: block;
  height: calc(var(--topbar-height) / 2);
}
.topbar__iconlinks a {
  text-decoration: none;
  font-size: 14px;
  margin: 0 20px 0 0;
}
@media (hover: hover) {
  .topbar__iconlinks a:hover {
    text-decoration: underline;
  }
}
.topbar__searchform {
  position: relative;
}
.topbar__searchinput {
  width: 200px;
}
.topbar__searchbutton {
  border: 0;
  padding: 0;
  height: auto;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
}
@media (hover: hover) {
  .topbar__searchbutton:hover {
    background: none;
  }
}
.topbar__mobilesearchform {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  background: #fff;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  padding: 1.5rem 1rem;
}
html.mobile-search-open .topbar__mobilesearchform {
  transform: translateY(var(--topbar-height));
}
.topbar__mobilesearchform .topbar__searchform {
  width: calc(90vw - 42px);
  max-width: 500px;
}
.topbar__mobilesearchform .topbar__searchinput {
  width: 100%;
}
.topbar__mobilesearchform form {
  width: 100%;
}
.topbar__menubuttoncontainer, .topbar__newsbuttoncontainer, .topbar__searchbuttoncontainer {
  display: none;
}
.topbar__searchbuttoncontainer, .topbar__newsbuttoncontainer {
  display: none;
  flex-direction: column;
  border-left: 1px solid #e8ebee;
  width: 60px;
  height: 100%;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}
html.mobile-news-open .topbar__newsbuttoncontainer {
  background: #fff;
}
.topbar__newsbuttoncontainer .icon-news path,
.topbar__newsbuttoncontainer .icon-news rect {
  transition: fill 0.3s;
  fill: #fff;
}
html.mobile-news-open .topbar__newsbuttoncontainer .icon-news path,
html.mobile-news-open .topbar__newsbuttoncontainer .icon-news rect {
  fill: #d40f4d;
}
.topbar__searchopen {
  display: inline;
}
.topbar__searchopen .icon-search .stroke {
  stroke: #fff;
}
.topbar__searchopen svg {
  display: block;
}
html.mobile-search-open .topbar__searchopen {
  display: none;
}
.topbar__newsopen {
  display: inline;
}
.topbar__newsopen svg {
  display: block;
}
.topbar__searchbuttonlabel {
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 100%;
  margin: 9px 0 0;
  color: #fff;
}
.topbar__searchclose, .topbar__newsclose {
  display: none;
  height: 19px;
}
html.mobile-search-open .topbar__searchclose {
  display: inline;
}
html.mobile-news-open .topbar__newsclose {
  display: inline;
}
.topbar__menubuttoncontainer, .topbar__newsbuttoncontainer, .topbar__searchbuttoncontainer {
  display: flex;
}
.topbar__mediacontainer {
  border-left: 1px solid #e8ebee;
  height: 100%;
  display: flex;
}
.topbar__menubuttoncontainer {
  height: 100%;
  padding-right: 14px;
  border-right: 1px solid #e8ebee;
}