.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.slideshow {
  position: relative;
  max-width: 1464px;
  margin: 0 auto;
  filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.25));
}
html.page-home .slideshow {
  margin-bottom: 200px;
}
.slideshow--noimage {
  filter: none;
}
.slideshow .swiper-container {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 13vw), 100% 0, 100% calc(100% - 13vw), 0 100%);
}
.slideshow__item {
  width: 100%;
  height: 1000px;
}
.slideshow__bgoverlay, .slideshow__bgimage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.slideshow__bgoverlay {
  pointer-events: none;
  z-index: 1;
}
@supports (mix-blend-mode: multiply) {
  .slideshow__bgoverlay {
    background: linear-gradient(rgba(159, 153, 153, 0.36) 0%, #504d4d 100%);
    mix-blend-mode: multiply;
  }
}
@supports not (mix-blend-mode: multiply) {
  .slideshow__bgoverlay {
    background: rgba(0, 0, 0, 0.5);
  }
}
.slideshow__info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  position: relative;
  z-index: 2;
  margin: 0 24px;
}
.slideshow__info-inner {
  position: relative;
  top: 3vw;
}
.slideshow__title {
  font-size: 67px;
  line-height: 100%;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: 134px; /* Fallback for non-webkit */
  font-size: 67px !important;
  line-height: 1 !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  margin-bottom: 2px;
  word-break: break-word;
}
.slideshow__sitetype {
  font-size: 34px;
  line-height: 100%;
  font-weight: bold;
  margin-bottom: 32px;
}
.slideshow__description {
  color: #fff;
  margin-bottom: 80px;
}
.slideshow .swiper-button-next {
  right: 25px;
}
.slideshow .swiper-button-prev {
  left: 25px;
}
.slideshow .swiper-pagination-bullets {
  display: none;
  right: 56px;
  bottom: 400px;
  width: auto;
  left: auto;
  flex-direction: column-reverse;
}
.slideshow .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 24px;
  height: 24px;
  background: transparent;
  border-radius: 100%;
  border: 2px solid #fff;
  margin: 10px 0 0;
  opacity: 1;
  position: relative;
}
.slideshow .swiper-pagination-bullets .swiper-pagination-bullet::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slideshow .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  animation: pop 0.6s linear;
  animation-fill-mode: forwards;
}
.slideshow .roundnavbutton {
  display: none;
}
.slideshow .container {
  text-align: center;
  max-width: 60vw;
}
.slideshow .swiper-button-prev,
.slideshow .swiper-button-next {
  display: none;
  top: auto;
  left: auto;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: skewY(-8deg);
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 24px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.1em;
  margin: 0;
  top: auto;
  right: auto;
  background: #f17156;
}
.slideshow .swiper-button-prev .icon-arrow-right-long .fill,
.slideshow .swiper-button-next .icon-arrow-right-long .fill {
  fill: #fff;
}
.slideshow .swiper-button-prev::after,
.slideshow .swiper-button-next::after {
  display: none;
}
.slideshow .swiper-button__fullwidth {
  display: none;
}
.slideshow .swiper-button-prev {
  width: 110px;
  left: calc(100% - 250px);
  transform: skewY(-8deg) translateY(25px);
}
.slideshow .swiper-button-prev svg.icon-arrow-right-long {
  transform: scaleX(-1);
}
.slideshow .swiper-button-next {
  width: 110px;
  left: calc(100% - 130px);
  transform: skewY(-8deg) translateY(27px);
}
.slideshow .swiper-button-next .swiper-button__label {
  display: none;
}
@media (min-width: 576px) {
  .slideshow .swiper-button-prev {
    width: 110px;
    left: calc(100% - 424px);
    transform: skewY(-8deg) translateY(25px);
  }
  .slideshow .swiper-button-next {
    width: 224px;
    left: calc(100% - 300px);
    transform: skewY(-8deg) translateY(17px);
  }
  .slideshow .swiper-button-next .swiper-button__label {
    display: flex;
  }
}
@media (min-width: 768px) {
  .slideshow .swiper-button-prev {
    transform: skewY(-8deg) translateY(32px);
  }
  .slideshow .swiper-button-next {
    transform: skewY(-8deg) translateY(24px);
  }
  .slideshow .swiper-pagination-bullets {
    display: inline-flex;
  }
  .slideshow__info {
    margin: 0 0 0 128px;
    max-width: 480px;
  }
}
@media (min-width: 992px) {
  .slideshow .swiper-button-prev {
    transform: skewY(-8deg) translateY(40px);
  }
  .slideshow .swiper-button-next {
    transform: skewY(-8deg) translateY(32px);
  }
}
@media (min-width: 1464px) {
  .slideshow--hasmaxwidth {
    max-width: 100vw;
    filter: none;
  }
  html.page-home .slideshow--hasmaxwidth {
    margin-bottom: 210px;
  }
  .slideshow--hasmaxwidth .swiper-button-prev,
  .slideshow--hasmaxwidth .swiper-button-next {
    display: none;
  }
  .slideshow--hasmaxwidth .roundnavbutton {
    display: flex;
    position: absolute;
    top: 60px;
    left: 0;
    transform: translateX(calc(-100% - 20px));
  }
}
@media (min-width: 1464px) and (min-width: 1564px) {
  .slideshow--hasmaxwidth .roundnavbutton {
    transform: translateX(-50%);
  }
}
@media (min-width: 1464px) {
  .slideshow--hasmaxwidth .roundnavbutton.roundnavbutton--prev {
    top: 140px;
    transform: translateX(calc(-100% - 20px)) scaleX(-1);
  }
}
@media (min-width: 1464px) and (min-width: 1564px) {
  .slideshow--hasmaxwidth .roundnavbutton.roundnavbutton--prev {
    transform: translateX(-50%) scaleX(-1);
  }
}
@media (min-width: 1464px) {
  .slideshow--hasmaxwidth .slideshow__horizontalclipper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc((100vw - 1464px) / 2);
    background: #fff;
    z-index: 99999;
  }
  .slideshow--hasmaxwidth .slideshow__horizontalclipper--left {
    left: 0;
  }
  .slideshow--hasmaxwidth .slideshow__horizontalclipper--right {
    right: 0;
  }
  .slideshow--hasmaxwidth .slideshow__info-inner {
    transform: translateX(calc((100vw - 1464px) / 2));
  }
  .slideshow--hasmaxwidth .swiper-pagination-bullets {
    transform: translateX(calc(-1 * (100vw - 1464px) / 2));
  }
}
.slideshow.slideshow--single-slide .swiper-button-prev,
.slideshow.slideshow--single-slide .swiper-button-next,
.slideshow.slideshow--single-slide .swiper-pagination-bullets,
.slideshow.slideshow--single-slide .roundnavbutton {
  display: none;
}

@keyframes pop {
  0% {
    width: 0%;
    height: 0%;
  }
  50% {
    width: 23px;
    height: 23px;
  }
  100% {
    width: 10px;
    height: 10px;
  }
}