.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.button,
.wh-form__button {
  font-size: 18px;
  line-height: 100%;
  align-items: center;
  border-radius: 25px;
  border: 0;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;
  justify-content: flex-start;
  max-width: 100%;
  min-height: 52px;
  min-width: 208px;
  outline: none;
  padding: 16px 50px 16px 30px;
  position: relative;
  text-decoration: none;
  text-transform: lowercase;
  transition: background 0.3s, color 0.3s, opacity 0.3s, box-shadow 0.3s;
  background-color: #C9DFF8;
}
html.site-general .button,
html.site-general .wh-form__button {
  color: #d40f4d;
}
html.site-advice .button,
html.site-advice .wh-form__button {
  color: #f17156;
}
html.site-work .button,
html.site-work .wh-form__button {
  color: #8fb8ec;
}
html.site-care .button,
html.site-care .wh-form__button {
  color: #4969f8;
}
html.site-general .button .button__icon .fill,
html.site-general .wh-form__button .button__icon .fill {
  fill: #d40f4d;
}
html.site-advice .button .button__icon .fill,
html.site-advice .wh-form__button .button__icon .fill {
  fill: #f17156;
}
html.site-work .button .button__icon .fill,
html.site-work .wh-form__button .button__icon .fill {
  fill: #8fb8ec;
}
html.site-care .button .button__icon .fill,
html.site-care .wh-form__button .button__icon .fill {
  fill: #4969f8;
}
html.site-general .button .button__arrowright .icon-arrow-right .stroke,
html.site-general .wh-form__button .button__arrowright .icon-arrow-right .stroke {
  stroke: #d40f4d;
}
html.site-advice .button .button__arrowright .icon-arrow-right .stroke,
html.site-advice .wh-form__button .button__arrowright .icon-arrow-right .stroke {
  stroke: #f17156;
}
html.site-work .button .button__arrowright .icon-arrow-right .stroke,
html.site-work .wh-form__button .button__arrowright .icon-arrow-right .stroke {
  stroke: #8fb8ec;
}
html.site-care .button .button__arrowright .icon-arrow-right .stroke,
html.site-care .wh-form__button .button__arrowright .icon-arrow-right .stroke {
  stroke: #4969f8;
}
html.site-work .button, html.site-care .button,
html.site-work .wh-form__button,
html.site-care .wh-form__button {
  background-color: #F17156;
  color: #fff;
}
html.site-work .button .button__icon .fill, html.site-care .button .button__icon .fill,
html.site-work .wh-form__button .button__icon .fill,
html.site-care .wh-form__button .button__icon .fill {
  fill: #fff;
}
html.site-work .button .button__arrowright .icon-arrow-right .stroke, html.site-care .button .button__arrowright .icon-arrow-right .stroke,
html.site-work .wh-form__button .button__arrowright .icon-arrow-right .stroke,
html.site-care .wh-form__button .button__arrowright .icon-arrow-right .stroke {
  stroke: #fff;
}
@media (hover: hover) {
  .button:hover,
  .wh-form__button:hover {
    text-decoration: none;
  }
  html.site-general .button:hover,
  html.site-general .wh-form__button:hover {
    color: #d40f4d;
  }
  html.site-advice .button:hover,
  html.site-advice .wh-form__button:hover {
    color: #f17156;
  }
  html.site-work .button:hover,
  html.site-work .wh-form__button:hover {
    color: #8fb8ec;
  }
  html.site-care .button:hover,
  html.site-care .wh-form__button:hover {
    color: #4969f8;
  }
  html .button:hover,
  html .wh-form__button:hover {
    background-color: #fff;
  }
  html.site-general .button:hover .button__icon .fill,
  html.site-general .wh-form__button:hover .button__icon .fill {
    fill: #d40f4d;
  }
  html.site-advice .button:hover .button__icon .fill,
  html.site-advice .wh-form__button:hover .button__icon .fill {
    fill: #f17156;
  }
  html.site-work .button:hover .button__icon .fill,
  html.site-work .wh-form__button:hover .button__icon .fill {
    fill: #8fb8ec;
  }
  html.site-care .button:hover .button__icon .fill,
  html.site-care .wh-form__button:hover .button__icon .fill {
    fill: #4969f8;
  }
  html.site-general .button:hover .button__arrowright .stroke,
  html.site-general .wh-form__button:hover .button__arrowright .stroke {
    stroke: #d40f4d;
  }
  html.site-advice .button:hover .button__arrowright .stroke,
  html.site-advice .wh-form__button:hover .button__arrowright .stroke {
    stroke: #f17156;
  }
  html.site-work .button:hover .button__arrowright .stroke,
  html.site-work .wh-form__button:hover .button__arrowright .stroke {
    stroke: #8fb8ec;
  }
  html.site-care .button:hover .button__arrowright .stroke,
  html.site-care .wh-form__button:hover .button__arrowright .stroke {
    stroke: #4969f8;
  }
}
.button__arrowright,
.wh-form__button__arrowright {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
}
.button__icon,
.wh-form__button__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
.button__icon svg,
.wh-form__button__icon svg {
  width: 19px;
}
.button--secondary,
.wh-form__button--secondary {
  border: 2px solid transparent;
  box-shadow: none;
}
html.site-general .button--secondary,
html.site-general .wh-form__button--secondary {
  color: #d40f4d;
}
html.site-advice .button--secondary,
html.site-advice .wh-form__button--secondary {
  color: #f17156;
}
html.site-work .button--secondary,
html.site-work .wh-form__button--secondary {
  color: #8fb8ec;
}
html.site-care .button--secondary,
html.site-care .wh-form__button--secondary {
  color: #4969f8;
}
html.site-general .button--secondary,
html.site-general .wh-form__button--secondary {
  border-color: #d40f4d;
}
html.site-advice .button--secondary,
html.site-advice .wh-form__button--secondary {
  border-color: #f17156;
}
html.site-work .button--secondary,
html.site-work .wh-form__button--secondary {
  border-color: #8fb8ec;
}
html.site-care .button--secondary,
html.site-care .wh-form__button--secondary {
  border-color: #4969f8;
}
html[class*=site-] .button--secondary,
html[class*=site-] .wh-form__button--secondary {
  background-color: transparent;
}
html.site-general .button--secondary .button__arrowright .icon-arrow-right .stroke,
html.site-general .wh-form__button--secondary .button__arrowright .icon-arrow-right .stroke {
  stroke: #d40f4d;
}
html.site-advice .button--secondary .button__arrowright .icon-arrow-right .stroke,
html.site-advice .wh-form__button--secondary .button__arrowright .icon-arrow-right .stroke {
  stroke: #f17156;
}
html.site-work .button--secondary .button__arrowright .icon-arrow-right .stroke,
html.site-work .wh-form__button--secondary .button__arrowright .icon-arrow-right .stroke {
  stroke: #8fb8ec;
}
html.site-care .button--secondary .button__arrowright .icon-arrow-right .stroke,
html.site-care .wh-form__button--secondary .button__arrowright .icon-arrow-right .stroke {
  stroke: #4969f8;
}
@media (hover: hover) {
  html.site-general .button--secondary:hover,
  html.site-general .wh-form__button--secondary:hover {
    background-color: #d40f4d;
  }
  html.site-advice .button--secondary:hover,
  html.site-advice .wh-form__button--secondary:hover {
    background-color: #f17156;
  }
  html.site-work .button--secondary:hover,
  html.site-work .wh-form__button--secondary:hover {
    background-color: #8fb8ec;
  }
  html.site-care .button--secondary:hover,
  html.site-care .wh-form__button--secondary:hover {
    background-color: #4969f8;
  }
  html[class*=site-] .button--secondary:hover,
  html[class*=site-] .wh-form__button--secondary:hover {
    color: #fff;
  }
  .button--secondary:hover .button__arrowright .icon-arrow-right,
  .button--secondary:hover .stroke,
  .wh-form__button--secondary:hover .button__arrowright .icon-arrow-right,
  .wh-form__button--secondary:hover .stroke {
    stroke: #fff !important;
  }
}
html.site-general .button--secondary .icon-arrow-right .stroke,
html.site-general .wh-form__button--secondary .icon-arrow-right .stroke {
  stroke: #d40f4d;
}
html.site-advice .button--secondary .icon-arrow-right .stroke,
html.site-advice .wh-form__button--secondary .icon-arrow-right .stroke {
  stroke: #f17156;
}
html.site-work .button--secondary .icon-arrow-right .stroke,
html.site-work .wh-form__button--secondary .icon-arrow-right .stroke {
  stroke: #8fb8ec;
}
html.site-care .button--secondary .icon-arrow-right .stroke,
html.site-care .wh-form__button--secondary .icon-arrow-right .stroke {
  stroke: #4969f8;
}
html[class*=site-] .button--white,
html[class*=site-] .wh-form__button--white {
  background-color: #fff;
}
.button.button--secondary.type-general,
.wh-form__button.button--secondary.type-general {
  color: #d40f4d;
}
.button.button--secondary.type-general .icon-arrow-right .stroke,
.wh-form__button.button--secondary.type-general .icon-arrow-right .stroke {
  stroke: #d40f4d;
}
.button.button--secondary.type-advice,
.wh-form__button.button--secondary.type-advice {
  color: #f17156;
}
.button.button--secondary.type-advice .icon-arrow-right .stroke,
.wh-form__button.button--secondary.type-advice .icon-arrow-right .stroke {
  stroke: #f17156;
}
.button.button--secondary.type-work,
.wh-form__button.button--secondary.type-work {
  color: #8fb8ec;
}
.button.button--secondary.type-work .icon-arrow-right .stroke,
.wh-form__button.button--secondary.type-work .icon-arrow-right .stroke {
  stroke: #8fb8ec;
}
.button.button--secondary.type-care,
.wh-form__button.button--secondary.type-care {
  color: #4969f8;
}
.button.button--secondary.type-care .icon-arrow-right .stroke,
.wh-form__button.button--secondary.type-care .icon-arrow-right .stroke {
  stroke: #4969f8;
}
.button--noborder,
.wh-form__button--noborder {
  border: none;
}
.button--icon,
.wh-form__button--icon {
  padding-left: 54px;
}
html .button--loading,
html .wh-form__button--loading {
  outline: none;
  background: #ddd !important;
  pointer-events: none;
  opacity: 0.3;
}

html[class*=site-] .button--secondary.type-general {
  border-color: #d40f4d;
}
html[class*=site-] .button--secondary.type-general .button__arrowright .icon-arrow-right .stroke {
  stroke: #d40f4d;
}
@media (hover: hover) {
  html[class*=site-] .button--secondary.type-general:hover {
    background-color: #d40f4d;
    border-color: #d40f4d;
  }
  html[class*=site-] .button--secondary.type-general:hover * {
    color: #fff !important;
  }
}

html[class*=site-] .button--secondary.type-advice {
  border-color: #f17156;
}
html[class*=site-] .button--secondary.type-advice .button__arrowright .icon-arrow-right .stroke {
  stroke: #f17156;
}
@media (hover: hover) {
  html[class*=site-] .button--secondary.type-advice:hover {
    background-color: #f17156;
    border-color: #f17156;
  }
  html[class*=site-] .button--secondary.type-advice:hover * {
    color: #fff !important;
  }
}

html[class*=site-] .button--secondary.type-work {
  border-color: #8fb8ec;
}
html[class*=site-] .button--secondary.type-work .button__arrowright .icon-arrow-right .stroke {
  stroke: #8fb8ec;
}
@media (hover: hover) {
  html[class*=site-] .button--secondary.type-work:hover {
    background-color: #8fb8ec;
    border-color: #8fb8ec;
  }
  html[class*=site-] .button--secondary.type-work:hover * {
    color: #fff !important;
  }
}

html[class*=site-] .button--secondary.type-care {
  border-color: #4969f8;
}
html[class*=site-] .button--secondary.type-care .button__arrowright .icon-arrow-right .stroke {
  stroke: #4969f8;
}
@media (hover: hover) {
  html[class*=site-] .button--secondary.type-care:hover {
    background-color: #4969f8;
    border-color: #4969f8;
  }
  html[class*=site-] .button--secondary.type-care:hover * {
    color: #fff !important;
  }
}