.badge--menu {
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  left: 2px;
  line-height: 100%;
  padding: 4px 6px 3px 6px;
  position: relative;
  top: -13px;
  background: #fff;
  min-width: 20px;
}