.hamburger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 16px;
  background-color: transparent;
  color: inherit;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  transition: 0.3s ease;
  border: 0;
}
.hamburger:focus {
  outline: none;
}
.hamburger__text {
  margin: 22px 0 0;
  font-size: 10px;
  font-weight: 700;
  line-height: 100%;
  color: #fff;
}
.hamburger__bars {
  display: block;
  position: relative;
  width: 24px;
  height: 2px;
  transition: 0.3s;
  top: 0;
  background: #fff;
}
.hamburger__bars:before, .hamburger__bars:after {
  background: #fff;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
.hamburger__bars:before {
  transform: translate(0, -7px);
}
.hamburger__bars:after {
  transform: translate(0, 7px);
}
html.mobile-menu-open .hamburger .hamburger__bars {
  background-color: transparent;
}
html.mobile-menu-open .hamburger .hamburger__bars:before {
  transform: rotate(45deg);
}
html.mobile-menu-open .hamburger .hamburger__bars:after {
  transform: rotate(-45deg);
}