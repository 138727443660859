.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.w-services__small, .w-services__wide {
  margin: 0 auto;
  max-width: calc(100vw - 2rem);
}
.w-services__small {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  padding: 3rem 0;
}
@media (min-width: 768px) {
  .w-services__small {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.w-services__small .container {
  max-width: initial;
}
.w-services__wide {
  display: none;
}
@media (min-width: 992px) {
  .w-services__small {
    display: none;
  }
  .w-services__wide {
    display: block;
  }
}
.w-services__background {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 13vw), 100% 0, 100% calc(100% - 13vw), 0 100%);
  position: absolute;
  bottom: -400px;
  top: -200px;
  left: 0;
  right: 0;
  background: #ecf3fc;
  z-index: -1;
  top: -850px;
  bottom: 170px;
}
.w-services .c-container {
  margin: 0 auto;
  width: 1364px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: -20px 20px 30px rgba(0, 0, 0, 0.16);
  padding: 1rem 3rem 5rem 5rem;
  background: #fff;
}
@media screen and (max-width: 1364px) {
  .w-services .c-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.w-services .c-container__col--left {
  flex: 0 0 383px;
  padding: 0 32px 0 0;
}
.w-services .c-container__col--right {
  flex: 1;
  padding: 0 0 0 32px;
}
.w-services .c-container__itemtitle {
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 1rem;
  font-weight: 700;
}
.w-services .c-container__imagecontainer {
  width: 100%;
  height: 250px;
  margin: 0 0 2rem 0;
  position: relative;
}
.w-services .c-container__image {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  opacity: 0;
  transition: opacity 0.3s;
}
.w-services .c-container__image.active {
  opacity: 1;
}
.w-services .c-container__heading {
  font-size: 52px;
  line-height: 100%;
  margin-top: 1.5rem;
}
.w-services .c-container .ctatextlinks__title {
  text-transform: lowercase;
  font-weight: 700;
}
.w-services .c-container__itemdescriptioncontainer {
  position: relative;
}
.w-services .c-container__itemtitledescription {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s;
}
.w-services .c-container__itemtitledescription.active {
  opacity: 1;
}
@media (min-width: 1040px) {
  .w-services .c-container__items {
    display: flex;
    flex-flow: row wrap;
  }
  .w-services .c-container__items .ctatextlinks {
    width: 50%;
    padding: 0 40px 0 0;
  }
}