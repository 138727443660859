.angle--top-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%)) {
  .angle--top-left::before, .angle--top-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--top-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 100%)) {
  .angle--top-right::before, .angle--top-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--top-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
}

.angle--bottom-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--bottom-left::before, .angle--bottom-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--bottom-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--bottom-right::before, .angle--bottom-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--bottom-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-left-left::before, .angle--both-left-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-left::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-right-right::before, .angle--both-right-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-right::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-left-right {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
}
@supports not (clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%)) {
  .angle--both-left-right::before, .angle--both-left-right::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-left-right::before {
    top: 0;
    border-width: 8vw 100vw 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-left-right::after {
    bottom: 0;
    border-width: 0 0 8vw 100vw;
    border-color: transparent transparent #fff transparent;
  }
}

.angle--both-right-left {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw));
}
@supports not (clip-path: polygon(0 0, 100% calc(0% + 7vw), 100% 100%, 0 calc(100% - 7vw))) {
  .angle--both-right-left::before, .angle--both-right-left::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }
  .angle--both-right-left::before {
    top: 0;
    border-width: 8vw 0 0 100vw;
    border-color: #fff transparent transparent transparent;
  }
  .angle--both-right-left::after {
    bottom: 0;
    border-width: 0 100vw 8vw 0;
    border-color: transparent transparent #fff transparent;
  }
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196F3;
  text-align: center;
  padding: 10% 0;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.site-general .custom-text-decoration:after {
  background: #d40f4d;
}

html.site-advice .custom-text-decoration:after {
  background: #f17156;
}

html.site-work .custom-text-decoration:after {
  background: #8fb8ec;
}

html.site-care .custom-text-decoration:after {
  background: #4969f8;
}

.sidebarcontact {
  position: fixed;
  top: 219px;
  right: 0;
  z-index: 10000;
  box-shadow: -20px 10px 20px rgba(0, 0, 0, 0.16);
  transition: opacity 0.3s;
}
.sidebarcontact__container.initialized {
  display: block !important;
}
html.hidesidebarcontact .sidebarcontact {
  opacity: 0;
  pointer-events: none;
}
.sidebarcontact__close {
  display: none;
  position: absolute;
  padding: 10px;
  top: 0;
  right: 0;
}
@media (hover: none) {
  .sidebarcontact__close {
    display: block;
  }
}
.sidebarcontact__close svg {
  width: 18px;
  height: auto;
}
.sidebarcontact__sidebarcontent {
  display: none;
  height: 70px;
  display: flex;
  align-items: center;
  color: #fff;
}
.sidebarcontact__info {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #fff !important;
  text-decoration: none;
  justify-content: center;
  margin-left: 4px;
}
.sidebarcontact__subtitle {
  font-size: 17px;
  line-height: 100%;
  margin-bottom: 4px;
  font-weight: 400;
}
.sidebarcontact__icon {
  height: 100%;
  width: 70px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.sidebarcontact__phone, .sidebarcontact__email, .sidebarcontact__address {
  font-size: 20px;
  line-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 258px;
  transition: transform 0.3s;
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 700;
  transform: translateX(100%);
}
.sidebarcontact__phone.show, .sidebarcontact__email.show, .sidebarcontact__address.show {
  transform: translateX(0);
  box-shadow: -20px 10px 20px rgba(0, 0, 0, 0.16);
}
.sidebarcontact__address {
  min-width: 420px;
}
.sidebarcontact__link {
  color: #fff !important;
  text-decoration: none;
  display: block;
}
.sidebarcontact__mainicon {
  height: 70px;
  padding: 25px;
  display: flex;
  border-bottom: 1px solid #fff;
  align-items: center;
}
.sidebarcontact__mainicon:hover {
  cursor: pointer;
}
.sidebarcontact__mainicon--address {
  border-bottom: 0px;
}